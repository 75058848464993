import * as React from "react";
import cx from "classnames";
import { ReactComponent as CheckIconBlack } from "../icons/checkmark-black.svg";
import { ReactComponent as CheckIconBlue } from "../icons/checkmark-blue.svg";

import styles from "./checkbox.module.css";

interface IProps {
  name: string;
  left?: boolean;
  blue?: boolean;
  checked: boolean;
  onChange: any;
  onClick?: any;
  label?: string;
  LabelComponent?: any;
  className?: string;
  checkmarkClassName?: string;
  checkedClassName?: string;
  containerClassName?: string;
  disabled?: boolean;
  checkIcon?: React.ReactElement;
}

const Checkbox = ({
  name,
  checked = false,
  left = false,
  blue = false,
  onChange,
  onClick = () => {},
  label,
  LabelComponent,
  className,
  checkmarkClassName,
  checkedClassName,
  containerClassName,
  disabled,
  checkIcon,
}: IProps) => {
  function onValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!disabled) {
      onChange(e);
    }
  }

  return (
    <div className={cx(styles.checkboxContainer, containerClassName)}>
      <label
        className={cx(
          styles.checkbox,
          className,
          left ? styles.checkboxLeft : undefined,
          checkedClassName && {
            [checkedClassName]: checked,
          }
        )}
      >
        <span
          className={cx(styles.checkmark, checkmarkClassName, {
            [styles.checked]: checked,
            [styles.blue]: blue,
            [styles.left]: left,
          })}
        >
          {checkIcon &&
            React.cloneElement(checkIcon, { className: styles.line })}
          {!checkIcon && blue && <CheckIconBlue className={styles.line} />}
          {!checkIcon && !blue && <CheckIconBlack className={styles.line} />}
        </span>
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onValueChange}
          onClick={onClick}
        />
        {LabelComponent ? <LabelComponent /> : null}
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
