/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { Head, Header, SubHeader } from "../../components/header";
import Confirm from "../../components/confirm/native";
import LinkButton from "../../components/link-button";
import Settings from "../../components/settings";
import Spinner from "../../components/spinner";

import { modalActions } from "../../resources/modal/modal.slice";

import { IPaymentMethod } from "../../resources/lease/types";
import { IStore } from "../../resources/types";
import { leaseActions } from "../../resources/lease/lease.slice";
import { isPaymentMethodSelectable } from "../../resources/lease/lease.service";

import styles from "./autopay.module.css";

interface ISetting {
  id: string;
  name: string;
  content: string | React.ReactElement;
  onClick?: () => void;
}

export default function Autopay() {
  const history = useHistory();
  const dispatch = useDispatch();

  const lease = useSelector((state: IStore) => state?.lease?.lease);
  const autopay = useSelector((state: IStore) => state.lease?.rent.autopay);
  const rent = useSelector((state: IStore) => state.lease?.rent);
  const paymentMethods = useSelector(
    (state: IStore) => state.lease?.paymentMethods
  )?.filter(isPaymentMethodSelectable);

  const isLoading = useSelector(
    (state: IStore) => state.lease?.loading.autopay
  );

  let selectedPaymentMethod: IPaymentMethod | undefined =
    paymentMethods && paymentMethods.length > 0 ? paymentMethods[0] : undefined;
  if (rent.paymentMethodId) {
    selectedPaymentMethod = paymentMethods?.find(
      (x) => x.id === rent.paymentMethodId
    );
  }

  const startDate = React.useMemo(() => {
    // Start date should be the first day of next month,
    // unless today is 6th day before the end of month,
    // then it should be the first day of month after next.

    if (moment().isBefore(moment().endOf("month").subtract(6, "days"))) {
      return moment().add(1, "month").startOf("month");
    }

    return moment().add(2, "months").startOf("month");
  }, []);

  React.useEffect(() => {
    if (lease?.id && autopay === undefined) {
      dispatch(leaseActions.fetchAutopay({ leaseId: lease.id }));
    }
  }, [dispatch, lease?.id, autopay]);

  React.useEffect(() => {
    if (!paymentMethods) {
      dispatch(leaseActions.fetchPaymentMethods());
    }
  }, [dispatch, paymentMethods]);

  React.useEffect(() => {
    if (
      paymentMethods &&
      paymentMethods.length > 0 &&
      !rent.paymentMethodId &&
      selectedPaymentMethod
    ) {
      dispatch(
        leaseActions.setAutopayPaymentMethod({
          paymentMethodId: selectedPaymentMethod?.id,
        })
      );
    }
    if (paymentMethods && paymentMethods.length > 0 && !selectedPaymentMethod) {
      dispatch(
        leaseActions.setAutopayPaymentMethod({
          paymentMethodId: paymentMethods[0].id,
        })
      );
    }
  }, [dispatch, paymentMethods, rent, selectedPaymentMethod]);

  async function action() {
    if (!lease) {
      return;
    }

    // 5 AM in EST timezone (UTC offset -5);
    // TODO: fix timezone once backend supports it
    const start = moment()
      .utcOffset("-05:00")
      .add(1, "day")
      .set({ hours: 5, minutes: 0, seconds: 0 });

    const modalId = "confirm-submit";
    dispatch(
      modalActions.show({
        id: modalId,
        dependant: true,
        component: (
          <Confirm
            id={modalId}
            action={() => {
              dispatch(
                leaseActions.createAutopay({
                  autopay: {
                    leaseId: lease.id,
                    paymentMethodId: rent.paymentMethodId!,
                    startDate: start.valueOf(),
                  },
                })
              );
            }}
            title="Set up autopay"
            text="Are you sure you want to set up autopay with the provided information?"
            yes="Yes"
            no="Cancel"
          />
        ),
      })
    );
  }

  async function cancel() {
    if (!autopay) {
      return;
    }

    const modalId = "confirm-cancel";
    dispatch(
      modalActions.show({
        id: modalId,
        dependant: true,
        component: (
          <Confirm
            id={modalId}
            action={() => {
              dispatch(leaseActions.cancelAutopay({ id: autopay.id }));
            }}
            title="Cancel autopay"
            text="Are you sure you want to cancel your automatic rent payments?"
            yes="Confirm"
            no="No"
            isRed
          />
        ),
      })
    );
  }

  const onManagePaymentMethodsClick = () => {
    history.push("/autopay/paymentMethod");
  };

  const settings: ISetting[] = [
    {
      id: "amount",
      name: "Amount",
      content: "Current balance",
    },
    {
      id: "date",
      name: "Pay date",
      content: "First of every month",
    },
    {
      id: "pm",
      name: "Payment method",
      content: (
        <LinkButton
          className={styles.setup}
          onClick={onManagePaymentMethodsClick}
        >
          {selectedPaymentMethod
            ? `${selectedPaymentMethod.name} *${selectedPaymentMethod.mask}`
            : "Add"}
        </LinkButton>
      ),
    },
  ];

  return (
    <div className={styles.pay}>
      <Head>
        <Header>{autopay ? "Manage autopay" : "Set up autopay"}</Header>
        <SubHeader>
          The amount set will be paid from your designated payment method on the
          pay date.
        </SubHeader>
      </Head>

      <div className={styles.content}>
        <Settings settings={settings} />
        <div className={styles.note}>
          {!autopay && (
            <div className={styles.bold}>
              The first payment will be processed on{" "}
              {startDate.format("MMMM D, YYYY")}.
            </div>
          )}
          Your payment will initiate 5-7 days before the first of the month, so
          that your property manager is paid on time.
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        {!autopay && (
          <LinkButton onClick={action} className={styles.save}>
            Set
          </LinkButton>
        )}
        {autopay && (
          <LinkButton onClick={cancel} className={styles.cancel}>
            Cancel autopay
          </LinkButton>
        )}
      </div>

      {isLoading && <Spinner />}
    </div>
  );
}
