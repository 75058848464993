import * as React from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";

import { modalActions } from "../../resources/modal/modal.slice";

import styles from "./native.module.css";

interface IProps {
  id: string;
  title: string;
  text: React.ReactElement | string;
  action: () => void;
  yes?: string;
  no?: string;
  isRed?: boolean;
}

export default function Confirm({
  id,
  title,
  text,
  yes,
  no,
  isRed,
  action,
}: IProps) {
  const dispatch = useDispatch();

  function close() {
    dispatch(modalActions.hide({ id }));
  }

  function confirm() {
    action();
    close();
  }

  return (
    <div className={styles.container}>
      <div className={styles.overlay} onClick={close} />
      <div className={styles.modal}>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.body}>{text}</div>
        </div>
        <div className={styles.buttons}>
          <button type="button" onClick={close}>
            {no || "No"}
          </button>
          <button
            type="button"
            onClick={confirm}
            className={cx(styles.confirm, isRed ? styles.red : undefined)}
          >
            {yes || "Yes"}
          </button>
        </div>
      </div>
    </div>
  );
}
