/**
 * @param epoch epoch time in seconds to turn into date
 */
export function epochSecondsToDate(epoch: number): Date {
  return new Date(epoch * 1000);
}

/**
 * @param date date to turn into epoch seconds
 */
export function dateToEpochSeconds(date: Date): number {
  return date && Math.round(date.getTime() / 1000);
}
