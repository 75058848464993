import * as React from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import BackButton from "../../../components/back-button";

import styles from "./header.module.css";

interface IProps {
  className?: string;
  showBackButton: boolean;
  backUrl?: string;
}

function Header({ className, backUrl, showBackButton }: IProps) {
  const history = useHistory();
  const onBackClick = () => {
    if (history.action === "POP") {
      history.push("/");
    } else if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <div id="header" className={cx(styles.container, className)}>
      <div className={styles.header}>
        {showBackButton && (
          <BackButton onClick={onBackClick} />
        )}
        <div className={styles.services} />
      </div>
    </div>
  );
}

export default Header;
