/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICommonState, IMessage } from "./types";

export const initialState: ICommonState = {
  error: undefined,
  success: undefined,
  isLoading: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    handleError(state: ICommonState, action: PayloadAction<Error>) {},
    setError(state, action: PayloadAction<IMessage>) {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    },
    clearError(state: ICommonState) {
      return {
        ...state,
        error: undefined,
      };
    },

    setSuccess(state: ICommonState, action: PayloadAction<IMessage>) {
      return {
        ...state,
        success: action.payload,
        isLoading: false,
      };
    },
    clearSuccess(state: ICommonState) {
      return {
        ...state,
        success: undefined,
      };
    },

    setIsLoading(state: ICommonState, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
});

export const commonActions = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
