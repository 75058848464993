import * as React from "react";

import styles from "./notfound.module.css";

function NotFound() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>
          404
          <br />
          Not Found
        </h1>
        <div className={styles.text}>
          The page you are looking for doesn’t exist.
        </div>
      </div>
    </div>
  );
}

export default NotFound;
