import * as React from "react";
import cx from "classnames";

import { ILease } from "../../resources/lease/types";
import { isModuleCompleted } from "../../resources/lease/lease.service";

import styles from "./progress-bar.module.css";

interface IProps {
  lease: ILease;
  className?: string;
}

export default function ProgressBar({ lease, className }: IProps) {
  const [total, completed] = [
    lease.modules.length,
    lease.modules.filter(isModuleCompleted).length,
  ];

  return (
    <div className={cx(styles.progressBar, className)}>
      <div
        className={styles.fill}
        style={{ transform: `scaleX(${completed / total})` }}
      />
      <div className={styles.progress}>
        {total ? Math.round((completed / total) * 100) : 0}% Complete
      </div>
    </div>
  );
}
