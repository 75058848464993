/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import cx from "classnames";
import moment from "moment";
import orderBy from "lodash/orderBy";
import every from "lodash/every";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ILease,
  IPaymentModule,
  IDwollaCustomer,
  ModuleType,
  ModuleStatus,
  DwollaCustomerStatus,
} from "../../resources/lease/types";
import { IStore } from "../../resources/types";
import {
  getNextPayment,
  hasFailedPayment,
  isPaymentModule,
} from "../../resources/lease/lease.service";

import ProgressBar from "../../components/progress-bar";
import { Head, Header, SubHeader } from "../../components/header";
import LinkButton from "../../components/link-button";
import PaymentError from "../../components/payment-error";
import Spinner from "../../components/spinner";

import Task from "./task";
import VerifyTask from "./verify-task";

import { authActions } from "../../resources/auth/auth.slice";

import { centsToDollars, getTaskTitle } from "../../helpers/common";

import { ReactComponent as ArrowIcon } from "../../components/icons/arrow-blue.svg";

import config from "../../config";

import styles from "./home.module.css";

export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();

  const lease = useSelector((state: IStore) => state.lease?.lease) as ILease;
  const dwollaCustomer = useSelector(
    (state: IStore) => state.lease.dwollaCustomer
  ) as IDwollaCustomer;
  const isHomePageLoading = useSelector((state: IStore) => state.lease?.loading.homePage);

  const download = () => {
    window.open(config.appStoreUrl);
  };

  function onClickTask(type: string) {
    return () => {
      const task = lease.modules.find((x) => x.type === type);
      if (!task || !task.canProceed) {
        history.push("/history");
        return;
      }
      history.push(`/payments/${type}`);
    };
  }

  function onConfirmClick() {
    history.push("/confirm");
  }

  function onVerifyClick() {
    history.push("/verify");
  }

  function onHistoryClick() {
    history.push("/history");
  }

  function onPaymentMethodClick() {
    history.push("/paymentMethod");
  }

  const confirmTask = lease.modules.find(
    (x) => x.type === ModuleType.CHECKINFO
  );
  const isConfirmTaskCompleted =
    confirmTask && confirmTask.status === ModuleStatus.COMPLETED;
  const isDwollaVerified =
    dwollaCustomer &&
    dwollaCustomer.status === DwollaCustomerStatus.DWOLLA_VERIFIED;
  const isDwollaSuspended =
    dwollaCustomer &&
    (dwollaCustomer.status === DwollaCustomerStatus.DWOLLA_SUSPENDED ||
      dwollaCustomer.status === DwollaCustomerStatus.DWOLLA_DEACTIVATED);

  const isVerificationCompleted =
    isConfirmTaskCompleted && isDwollaVerified && !isDwollaSuspended;

  const isFinished = every(
    lease.modules,
    (x) => x.status === ModuleStatus.COMPLETED
  );

  const nextPayment = getNextPayment(lease);

  const list = orderBy(
    lease.modules.filter(isPaymentModule),
    (x) => x.sequence
  ).map((task: IPaymentModule) => {
    const taskTitle = getTaskTitle({ task });
    return (
      <div
        key={task.id}
        className={styles.task}
        onClick={onClickTask(task.type)}
      >
        {taskTitle}
        <ArrowIcon className={styles.iconCenter} />
      </div>
    );
  });

  const logout = () => {
    dispatch(authActions.logout());
  };

  const onErrorClick = () => {
    history.push("/history");
  };

  const hasError = !!lease.modules.find(hasFailedPayment);

  const content = (
    <>
      <div className={styles.home}>
        <div className={styles.leaseInfo}>
          <div className={styles.leaseDate}>
            Your move-in date is{" "}
            <span className={styles.date}>
              {moment(lease.moveInDate).format("MMMM D, YYYY")}
            </span>
          </div>
          <hr />
        </div>

        {!isConfirmTaskCompleted && (
          <Task
            title="Confirm Information"
            description="Review your information to begin the move-in process."
            onClick={onConfirmClick}
          />
        )}
        {isConfirmTaskCompleted &&
          !isDwollaVerified &&
          dwollaCustomer !== undefined && (
            <VerifyTask
              dwollaCustomer={dwollaCustomer}
              onClick={onVerifyClick}
            />
          )}
        {isVerificationCompleted && (
          <>
            <div className={styles.balanceContainer}>
              {hasError && <PaymentError
                onClick={onErrorClick}
              />}
              <div className={styles.balanceElement}>
                <div className={styles.sectionTitle}>Current balance</div>
                <div className={styles.amount}>
                  {centsToDollars(lease.balance, { showCents: true })}
                </div>
                <div className={styles.text}>
                  {nextPayment && (
                    <>
                      Your payment is due{" "}
                      {moment(nextPayment.dueDate).format("MMMM DD, YYYY")}
                    </>
                  )}
                  {!nextPayment && lease.balance === 0 && (
                    <span className="green">
                      There is no balance due.
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.tasks}>{list}</div>
            </div>
          </>
        )}

        {isVerificationCompleted && (
          <div className={styles.additional}>
            <div className={styles.sectionTitle}>Additional settings</div>
            <LinkButton className={styles.link} onClick={onHistoryClick}>
              View payment history
            </LinkButton>
            <LinkButton className={styles.link} onClick={onPaymentMethodClick}>
              Manage payment method
            </LinkButton>
          </div>
        )}
      </div>
      <div className={styles.bottomContainer}>
        {isFinished && (
          <LinkButton className={styles.bottomBtn} onClick={download}>
            Download Latch App
          </LinkButton>
        )}
        <LinkButton className={cx(styles.bottomBtn, styles.logout)} onClick={logout}>
          Sign out
        </LinkButton>
      </div>
    </>
  );

  return (
    <>
      <Head grey>
        <Header>Hi, {lease.primaryResident.user.firstName}.</Header>
        <SubHeader>Welcome to {lease.building.name}.</SubHeader>
      </Head>

      <ProgressBar lease={lease} />


      {isHomePageLoading ? <Spinner /> : content}
    </>
  );
}
