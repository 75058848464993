import * as React from "react";
import PaymentMethods from "../../components/payment-methods";
import { Head, Header } from "../../components/header";

import styles from "./payment-method.module.css";

export default function PaymentMethod() {
  return (
    <div className={styles.pay}>
      <Head>
        <Header>Payment method</Header>
      </Head>

      <div className={styles.content}>
        <PaymentMethods />
      </div>
    </div>
  );
}
