import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { leaseActions } from "../../resources/lease/lease.slice";
import config from "../../config";

import Rent from "./rent";
import Onboarding from "./onboarding";
import Dashboard from "./dashboard";

type AppType = "RP1.0" | "LEASE_PAY" | "AUTO_PAY";

export default function Home() {
  const [appType, setAppType] = React.useState<AppType>("RP1.0");
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(leaseActions.fetchLease({ skipLoading: true }));
  }, [dispatch]);

  React.useEffect(() => {
    if (!config.isProduction && location.search === "?autopay") {
      setAppType("AUTO_PAY");
    } else if (!config.isProduction && location.search === "?leasepay") {
      setAppType("LEASE_PAY");
    } else {
      setAppType("RP1.0");
    }
  }, [location]);

  if (appType === "AUTO_PAY") {
    return <Rent />;
  }
  if (appType === "LEASE_PAY") {
    return <Onboarding />;
  }

  return <Dashboard />;
}
