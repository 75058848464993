import { PayloadAction } from "@reduxjs/toolkit";
import { all, take, takeEvery, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import { authActions } from "./auth.slice";
import { commonActions } from "../common/common.slice";
import { leaseActions } from "../lease/lease.slice";
import api from "./auth.api";
import leaseApi from "../lease/lease.api";

import { getReturnUrl, setReturnUrl } from "../../helpers/storage";

/**
 * https://getlatch.atlassian.net/browse/PAY-603
 * Check if user is authed in for login page.
 *
 * If they are already authed and try to go to login page, reroute
 * them to home page.
 * If not authed, let them stay on login page.
 */
export function* checkAuthWorker() {
  try {
    yield call(leaseApi.getLease);
    yield put(push("/"));
  } catch (err: any) {
    // continue and do nothing
  }
}

export function* loginWorker(
  data: PayloadAction<{ email: string; password: string }>
) {
  try {
    yield put(commonActions.setIsLoading(true));
    yield call(api.login, data.payload);
    yield put(leaseActions.fetchLease({}));

    yield take(leaseActions.setLease);

    yield put(commonActions.setIsLoading(false));

    const returnUrl = getReturnUrl();
    if (returnUrl) {
      setReturnUrl(undefined);
      yield put(push(returnUrl));
    } else {
      yield put(push("/"));
    }
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* resetPasswordWorker(
  data: PayloadAction<{ email: string; captcha: string }>
) {
  try {
    yield put(commonActions.setIsLoading(true));
    yield call(api.resetPassword, data.payload);
    yield put(commonActions.setIsLoading(false));
    yield put(
      commonActions.setSuccess({
        title: "Request Received",
        text: "If the account provided exists, it will receive an email with instructions to reset the password.",
      })
    );

    yield put(push("/login"));
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* logoutWorker() {
  try {
    yield put(commonActions.setIsLoading(true));
    yield call(api.logout);
    yield put(leaseActions.logout());
    yield put(commonActions.setIsLoading(false));

    yield put(push("/login"));
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* authWatcher() {
  yield all([
    takeEvery(authActions.login, loginWorker),
    takeEvery(authActions.resetPassword, resetPasswordWorker),
    takeEvery(authActions.logout, logoutWorker),
    takeEvery(authActions.checkAuth, checkAuthWorker)
  ]);
}
