import * as React from "react";
import cx from "classnames";
import moment from "moment-timezone";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ActionButton from "../../components/action-button";
import { Head, Header } from "../../components/header";
import Spinner from "../../components/spinner";
import Status from "../../components/status";
import Confirm from "../../components/confirm/native";

import PayFrom from "./pay-from";
import PayTo from "./pay-to";

import { centsToDollars } from "../../helpers/common";

import {
  TRANSACTION_STATUSES,
  TransactionStatus,
} from "../../resources/lease/types";
import { leaseActions } from "../../resources/lease/lease.slice";
import { modalActions } from "../../resources/modal/modal.slice";

import { IStore } from "../../resources/types";

import styles from "./details.module.css";

export default function HistoryDetails() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const transactions = useSelector(
    (state: IStore) => state.lease?.transactions
  );

  React.useEffect(() => {
    if (!transactions) {
      dispatch(leaseActions.fetchTransactions());
    }
  }, [dispatch, transactions]);

  React.useEffect(() => {
    if (!transactions) {
      return;
    }
    const transaction = transactions.find((x) => x.id === id);
    if (!transaction) {
      history.push("/404");
    }
  }, [id, transactions, history]);

  if (!transactions) {
    return <Spinner />;
  }

  const transaction = transactions.find((x) => x.id === id);

  const cancelPayment = () => {
    const modalId = "confirm-cancel";
    dispatch(
      modalActions.show({
        id: modalId,
        dependant: true,
        component: (
          <Confirm
            id={modalId}
            action={() => {
              dispatch(leaseActions.cancelTransaction({ id }));
            }}
            title="Cancel payment"
            text="Are you sure you want to cancel this payment?"
            isRed
          />
        ),
      })
    );
  };

  if (!transaction) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Head>
        <Header>Payment details</Header>
      </Head>
      <div className={styles.content}>
        <hr />

        <div className={styles.section}>
          <div className={styles.sectionTitle}>Amount</div>
          <div className={cx(styles.sectionContent, styles.sameRow)}>
            <span className={styles.amount}>
              {centsToDollars(transaction.amount, { showCents: true })}
            </span>
            <Status
              pending={transaction.status === TransactionStatus.PENDING}
              error={
                transaction.status === TransactionStatus.FAILED ||
                transaction.status === TransactionStatus.CANCELLED
              }
              completed={transaction.status === TransactionStatus.COMPLETED}
              text={TRANSACTION_STATUSES[transaction.status]}
            />
          </div>
        </div>

        <hr />

        <div className={styles.section}>
          <div className={styles.columns}>
            <div>
              <div className={styles.sectionTitle}>Payment initiated</div>
              <div className={cx(styles.sectionContent, styles.sameRow)}>
                <span className={styles.date}>
                  {moment(transaction.creationTimestamp).format("MMM DD, YYYY")}
                </span>
              </div>
            </div>
            <div>
              <div className={styles.sectionTitle}>Payment completed</div>
              <div className={cx(styles.sectionContent, styles.sameRow)}>
                <span className={styles.date}>
                  {transaction.completionTimestamp
                    ? moment(transaction.completionTimestamp).format(
                        "MMM DD, YYYY"
                      )
                    : "–"}
                </span>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <PayFrom transaction={transaction} />
        <PayTo transaction={transaction} />
      </div>
      {transaction.cancellable && (
        <ActionButton className={styles.cancelButton} onClick={cancelPayment}>
          Cancel payment
        </ActionButton>
      )}
    </div>
  );
}
