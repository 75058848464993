import * as React from "react";
import { useSelector } from "react-redux";

import Spinner from "../spinner";
import {
  ILease,
  IResident,
  IDwollaCustomer,
  DwollaCustomerStatus,
} from "../../resources/lease/types";
import leaseApi from "../../resources/lease/lease.api";
import { IStore } from "../../resources/types";

import config from "../../config";

const LOAD_DELAY_MS = 700;
const SHOW_DELAY_MS = 1100;
const SHOW_DELAY_WHEN_LOADED_MS = 500;

declare global {
  interface Window {
    dwolla?: any;
  }
  namespace JSX {
    interface IntrinsicElements {
      "dwolla-personal-vcr": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          terms: string;
          privacy: string;
          email?: string;
          firstName?: string;
          lastName?: string;
        },
        HTMLElement
      >;
      "dwolla-customer-update": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          terms: string;
          privacy: string;
          customerId: string;
          firstName?: string;
          lastName?: string;
          email?: string;
        },
        HTMLElement
      >;
      "dwolla-document-upload": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          customerId: string;
        },
        HTMLElement
      >;
    }
  }
}

interface IDwollaResponse {
  resource: string;
  response: {
    location?: string; // https://api-sandbox.dwolla.com/customers/3f9ad9de-e857-498b-bbe0-a2ab20a25e45
    code?: string;
    message?: string;
    _embedded?: {
      errors?: {
        code: string;
        message: string;
      }[];
    };
  };
}

const getDwollaCustomerId = (location: string): string | undefined => {
  const parts = location.split("/").reverse();
  if (parts.length < 2) {
    return undefined;
  }
  if (parts[1] !== "customers") {
    return undefined;
  }
  return parts[0];
};

let loadPromise: Promise<any> | undefined;

const load = (): Promise<void> =>
  new Promise((resolve: () => void): void => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://cdn.dwolla.com/v2.1.8/dwolla-web.js";

    s.addEventListener("load", (): void => {
      resolve();
    });

    document.body.appendChild(s);
  });

interface IWrapperProps {
  onSuccess: (customerId?: string) => void;
  onError: (error: string) => void;
  customerId?: string;
  children: React.ReactChild | React.ReactChild[];
}
function DwollaWrapper({
  children,
  customerId,
  onSuccess,
  onError,
}: IWrapperProps) {
  const [loaded, setLoaded] = React.useState(false);
  const [shown, setShown] = React.useState(false);

  React.useEffect(() => {
    if (loadPromise) {
      setLoaded(true);
      setTimeout(() => {
        setShown(true);
      }, SHOW_DELAY_WHEN_LOADED_MS);
      return;
    }

    const getDwollaToken = async (data: any) => {
      try {
        const token = await leaseApi.getDwollaToken({
          ...data,
          links: undefined,
          _links: data._links || data.links,
        });
        return token;
      } catch (e) {
        return new Error(
          "Something went wrong. Click back, and then return to this page to refresh and try again."
        );
      }
    };

    loadPromise = load()
      .then(() => {
        window.dwolla.configure({
          environment: config.dwolla.environment,
          styles: "/dwolla.css",
          token: getDwollaToken,
          success: async (data: IDwollaResponse) => {
            if (data.response?.location && !customerId) {
              onSuccess(getDwollaCustomerId(data.response.location));
            } else if (data.response?.message && data.response?.code) {
              onError(data.response.message);
            } else {
              onSuccess();
            }
          },
          error: async (e: Error) => {
            onError(e.message);
          },
        });
        setTimeout(() => {
          setLoaded(true);
        }, LOAD_DELAY_MS);
        setTimeout(() => {
          setShown(true);
        }, SHOW_DELAY_MS);
      })
      .catch((e: Error): void => {
        onError(e.message);
      });
  }, [customerId, onError, onSuccess]);

  if (!loaded) {
    return <Spinner opacity={1} />;
  }
  return (
    <>
      {!shown && <Spinner opacity={1} />}
      {children}
    </>
  );
}

interface IProps {
  dwollaCustomer?: IDwollaCustomer | null;
  resident?: IResident;
  onSuccess: (customerId?: string) => void;
  onError: (error: string) => void;
}

export default function Dwolla({
  dwollaCustomer,
  resident,
  onSuccess,
  onError,
}: IProps) {
  const lease = useSelector((state: IStore) => state.lease?.lease) as ILease;
  const { user } = lease.primaryResident;
  let component: React.ReactChild;
  switch (dwollaCustomer?.status) {
    case DwollaCustomerStatus.DWOLLA_UNVERIFIED:
      component = (
        <dwolla-customer-update
          customerId={dwollaCustomer.dwollaExternalCustomerId}
          firstName={user.firstName}
          lastName={user.lastName}
          email={user.email}
          terms={config.termsOfService}
          privacy={config.privacyPolicy}
        />
      );
      break;
    case DwollaCustomerStatus.DWOLLA_PENDING_RETRY:
      component = (
        <dwolla-customer-update
          customerId={dwollaCustomer.dwollaExternalCustomerId}
          terms={config.termsOfService}
          privacy={config.privacyPolicy}
        />
      );
      break;
    case DwollaCustomerStatus.DWOLLA_PENDING_DOCUMENT:
    case DwollaCustomerStatus.DWOLLA_PENDING_DOCUMENT_UPLOAD:
    case DwollaCustomerStatus.DWOLLA_PENDING_DOCUMENT_REVIEW:
    case DwollaCustomerStatus.DWOLLA_DOCUMENT_REVIEW_FAILED:
      component = (
        <dwolla-document-upload
          customerId={dwollaCustomer.dwollaExternalCustomerId}
        />
      );
      break;
    default:
      component = (
        <dwolla-personal-vcr
          email={resident?.user.email}
          firstName={resident?.user.firstName}
          lastName={resident?.user.lastName}
          terms={config.termsOfService}
          privacy={config.privacyPolicy}
        />
      );
      break;
  }
  return (
    <DwollaWrapper
      customerId={dwollaCustomer?.dwollaExternalCustomerId}
      onSuccess={onSuccess}
      onError={onError}
    >
      {component}
    </DwollaWrapper>
  );
}
