import * as React from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";
import Button from "../button";
import Modal from "../modal";

import { modalActions } from "../../resources/modal/modal.slice";

import styles from "./confirm.module.css";

interface IProps {
  id: string;
  show?: boolean;
  title: string;
  description?: string;
  yes: string;
  no?: string;
  action: (() => Promise<any>) | (() => void);
  titleClassName?: string;
  descriptionClassName?: string;
}

export default function Confirm({
  id,
  show,
  action,
  title,
  description,
  yes,
  no,
  titleClassName,
  descriptionClassName,
}: IProps) {
  const dispatch = useDispatch();

  const hideModal = () => {
    dispatch(modalActions.hide({ id }));
  };

  const onClick = () => {
    action();
    hideModal();
  };
  return (
    <Modal className={styles.modal} show={show} onClose={hideModal}>
      <div className={cx(styles.title, titleClassName)}>{title}</div>
      <div className={styles.content}>
        <div className={cx(styles.description, descriptionClassName)}>
          {description}
        </div>
        <div className={styles.actions}>
          <Button
            className={cx(
              styles.button,
              styles.yes,
              !no ? styles.fullWidth : undefined
            )}
            onClick={onClick}
          >
            {yes}
          </Button>
          {no && (
            <Button
              className={cx(styles.button, styles.deleteButton)}
              onClick={hideModal}
            >
              {no}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
