import * as React from "react";

import { ReactComponent as WarningIcon } from "../icons/warning.svg";

import styles from "./payment-error.module.css";

interface IProps {
  onClick: () => void;
  title?: string;
  text?: string;
}

export default function PaymentError({
  title= "There was an issue with your payment.",
  text= "View details and resolve.",
  onClick,
}: IProps) {
  return (
    <div className={styles.paymentError} onClick={onClick}>
      <WarningIcon />
      <div>
        <div className={styles.paymentErrorTitle}>
          {title}
        </div>
        <div className={styles.paymentErrorText}>
          {text}
        </div>
      </div>
    </div>
  );
}
