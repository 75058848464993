/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ActionButton from "../../components/action-button";
import { Head, Header } from "../../components/header";

import { leaseActions } from "../../resources/lease/lease.slice";

import { centsToDollars } from "../../helpers/common";

import { IStore } from "../../resources/types";

import styles from "./success.module.css";

export default function Success() {
  const history = useHistory();
  const dispatch = useDispatch();

  const paynow = useSelector((state: IStore) => state.lease?.paynow);
  const paymentMethods = useSelector(
    (state: IStore) => state.lease?.paymentMethods || []
  );
  if (!paynow) {
    return null;
  }
  const paymentMethod = paymentMethods.find(
    (x) => x.id === paynow.paymentMethodId
  );
  if (!paymentMethod) {
    return null;
  }

  async function action() {
    dispatch(leaseActions.submitPayNow());
    history.push("/");
  }

  return (
    <div className={styles.pay}>
      <Head>
        <Header>Your payment has been submitted.</Header>
      </Head>
      <div className={styles.content}>
        You have successfully submitted{" "}
        {paynow.amount
          ? centsToDollars(paynow.amount, { showCents: true })
          : "payment"}{" "}
        via{" "}
        <b>
          {paymentMethod.name} ending in {paymentMethod.mask}
        </b>
        .
      </div>

      <ActionButton onClick={action}>Done</ActionButton>
    </div>
  );
}
