import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import Alert from "../../components/alert";
import { IStore } from "../../resources/types";
import { commonActions } from "../../resources/common/common.slice";
import config from "../../config";

function parseText(text: string): React.ReactElement | string {
  switch (text) {
    case "INVALID_TOKEN":
    case "EXPIRED_TOKEN":
      return "Your session has expired. Please log in again.";
    case "INVALID_EMAIL_OR_PASSWORD":
      return (
        <>
          There was an issue with your username or password. Please try again or{" "}
          <a href="/forgot">reset password</a>.
        </>
      );
    case "USER_ACCOUNT_NOT_ACTIVE":
      return "User account is not active.";
    case "USER_ACCOUNT_TEMPORARILY_BLOCKED":
      return (
        <>
          User account is temporarily blocked. Please try again later or{" "}
          <a href={config.supportPage}>contact support</a>.
        </>
      );
    case "INTERNAL_SERVER_ERROR":
    case "BAD_REQUEST":
    case "OBJECT_NOT_FOUND":
    case "LEASE_TRANSLATION_ERROR":
    case "PLAID_REQUEST_ERROR":
    case "DWOLLA_REQUEST_ERROR":
    case "PLAID_SERVER_ERROR":
    case "PLAID_TOKEN_NOT_FOUND":
    case "DWOLLA_SERVER_ERROR":
      return "Something went wrong. Please try again.";
    case "LEASE_CONTAINS_INVALID_BUILDING":
    case "LEASE_CONTAINS_INVALID_UNIT":
      return "We are not able to confirm your lease details. Please contact your Property Manager.";
    case "INVALID_MODULE":
      return "An unexpected error occured. Please try again or contact your Property Manager.";
    case "LEASE_NOT_FOUND":
      return (
        <>
          There was an issue loading your information. Please{" "}
          <a href={config.supportPage}>contact support</a>.
        </>
      );
    case "MODULE_HAS_PENDING_TRANSACTION":
      return "Your payment method cannot be updated while there is a pending transaction.";
    case "MODULE_ALREADY_COMPLETED":
      return "Module already completed.";
    case "PAYMENT_METHOD_HAS_PENDING_TRANSACTIONS":
      return "Your payment method cannot be deleted while there is a pending transaction.";
    case "DWOLLA_DUPLICATE_RESOURCE":
    case "PAYMENT_METHOD_ALREADY_EXISTS":
      return "Payment method already added.";
    case "CUSTOMER_ALREADY_EXISTS":
      return (
        <>
          We were unable to verify your information. Please{" "}
          <a href={config.supportPage}>contact support</a> for assistance.
        </>
      );
    case "NO_DWOLLA_CUSTOMER_FOR_RESIDENT":
      return (
        <>
          An unexpected error occured. Please try again or{" "}
          <a href={config.supportPage}>contact support</a> for assistance.
        </>
      );
    case "PAYMENT_METHOD_HAS_INSUFFICIENT_BALANCE":
      return "You have insufficient funds in your account to proceed with this payment.";
    case "PAYMENT_METHOD_NOT_FOUND":
      return "We are unable to process your payment. Please contact your property manager for support.";
    default:
      return text;
  }
}

function parseTitle(text: string): string {
  switch (text) {
    case "PAYMENT_METHOD_HAS_INSUFFICIENT_BALANCE":
      return "Payment failed";
    case "BANK_ACCOUNT_LIMIT_REACHED":
      return "Bank account limit reached";
    default:
      return "Error";
  }
}

function ErrorProvider() {
  const dispatch = useDispatch();
  const err = useSelector((state: IStore) => state.common.error);
  const success = useSelector((state: IStore) => state.common.success);

  if (!err && !success) {
    return null;
  }

  if (err) {
    return (
      <Alert
        show
        title={parseTitle(err.title || err.text)}
        text={parseText(err.text)}
        onClose={() => dispatch(commonActions.clearError())}
      />
    );
  }
  if (success) {
    return (
      <Alert
        show
        title={success.title || "Success"}
        text={success.text}
        onClose={() => dispatch(commonActions.clearSuccess())}
      />
    );
  }
  return null;
}

ErrorProvider.displayName = "ErrorProvider";
export default ErrorProvider;
