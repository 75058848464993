import * as React from "react";
import cx from "classnames";

import styles from "./header.module.css";

interface IProps {
  className?: string;
  children: React.ReactChild | React.ReactChild[];
}

export default function Header({ className, children }: IProps) {
  return <h1 className={cx(styles.header, className)}>{children}</h1>;
}
