import * as React from "react";
import cx from "classnames";

import styles from "./header.module.css";

interface IProps {
  className?: string;
  grey?: boolean;
  black?: boolean;
  borderBottom?: boolean;
  children: React.ReactNode;
}

export default function Head({
  className,
  grey,
  black,
  borderBottom,
  children,
}: IProps) {
  return (
    <div
      className={cx(
        styles.head,
        grey && styles.grey,
        black && styles.black,
        borderBottom && styles.borderBottom,
        className
      )}
    >
      {children}
    </div>
  );
}
