import * as React from "react";
import cx from "classnames";

import styles from "./contact.module.css";

interface IProps {
  className?: string;
}

export default function Contact({ className }: IProps) {
  return (
    <div className={cx(styles.contactBlock, className)}>
      <div className={styles.title}>
        See something wrong? Please contact your property manager to correct
        your lease information before proceeding.
      </div>
    </div>
  );
}
