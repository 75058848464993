import * as React from "react";

import styles from "./alert.module.css";

interface IProps {
  show: boolean;
  title: string;
  text: React.ReactElement | string;
  cta?: string;
  onClose: () => void;
}

export default function Alert({ show, title, text, cta, onClose }: IProps) {
  if (!show) {
    return null;
  }

  function onOutsideClick() {
    onClose();
  }

  function close() {
    onClose();
  }

  return (
    <div className={styles.container}>
      <div className={styles.overlay} onClick={onOutsideClick} />
      <div className={styles.modal}>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.body}>{text}</div>
        </div>
        <div className={styles.buttons}>
          <button type="button" onClick={close}>
            {cta || "Dismiss"}
          </button>
        </div>
      </div>
    </div>
  );
}
