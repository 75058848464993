import * as React from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";

import ActionButton from "../../components/action-button";
import { Head, Header } from "../../components/header";
import Contact from "../../components/contact";
import {
  ILease,
  IResident,
  IModule,
  ModuleStatus,
} from "../../resources/lease/types";
import config from "../../config";

import { IStore } from "../../resources/types";
import { leaseActions } from "../../resources/lease/lease.slice";

import styles from "./confirm.module.css";

export default function ConfirmInformationPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const lease = useSelector((state: IStore) => state.lease?.lease) as ILease;

  const module = lease.modules.find((x) => x.type === "CHECKINFO") as IModule;

  const action = async () => {
    dispatch(leaseActions.confirmInformation({ moduleId: module.id }));
  };

  React.useEffect(() => {
    if (module.status === ModuleStatus.COMPLETED) {
      history.push("/verify");
    }
  }, [module, history]);

  return (
    <div className={styles.container}>
      <Head>
        <Header>Confirm lease</Header>
      </Head>

      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.title}>Name</div>
          <div className={styles.text}>
            {lease.primaryResident.user.firstName}{" "}
            {lease.primaryResident.user.lastName}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>Address</div>
          <div className={styles.text}>
            <span>{lease.building.name}</span>
            <br />
            <span>{lease.building.street || ""}</span>
            <br />
            <span>
              {lease.building.city}, {lease.building.state}{" "}
              {lease.building.zipCode}
            </span>
            <br />
            <span>Unit {lease.unit.name}</span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>Lease term</div>
          <div className={styles.text}>
            {moment(lease.startDate).format("MMM D, YYYY")} —{" "}
            {moment(lease.endDate).format("MMM D, YYYY")}
          </div>
        </div>
        {lease.otherResidents && lease.otherResidents.length > 0 && (
          <div className={styles.row}>
            <div className={styles.title}>Other tenants</div>
            <div className={styles.text}>
              {lease.otherResidents.map((r: IResident) => (
                <div key={r.id}>
                  {r.user.firstName} {r.user.lastName}
                  <br />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <hr />

      <div className={cx(styles.content, styles.copy)}>
        Latch uses Dwolla to process payments. By clicking "Confirm and
        continue", you will enter Dwolla's secure identity form where you will
        be able to enter personal information for identity verification and
        agree to Dwolla's{" "}
        <a href={config.dwolla.termsOfService}>Terms of Service</a> and{" "}
        <a href={config.dwolla.privacyPolicy}>Privacy Policy</a>.
      </div>

      <div className={styles.content}>
        <Contact />
      </div>

      <ActionButton onClick={() => action()}>Confirm and continue</ActionButton>
    </div>
  );
}
