import client from "../../api";
import {
  IPaymentMethod,
  IAddPaymentMethod,
  ILeaseResponse,
  IResidentInfoData,
  IDwollaCustomer,
  ITransactionResponse,
  VerificationStatus,
  IAutopayRequest,
  IAutopay,
  ILeaseBalance,
  ILeasePermissionResponse,
  ICreateModuleRequest,
  IPaymentModule,
  UserConsentKey,
  UserConsentAction,
  IUserConsentResponse,
} from "./types";
import { IResponse } from "../types";

import config from "../../config";

const api = {
  getLease: (): Promise<IResponse<ILeaseResponse>> =>
    client.get("/v1/lease", {}),

  getLeaseBalance: (
    year: number,
    month: number
  ): Promise<IResponse<ILeaseBalance>> =>
    client.get(`/v1/lease/balance/${year}/${month}`),

  updateLeaseResidentInfo: ({
    moduleId,
    data,
  }: {
    moduleId: string;
    data: IResidentInfoData;
  }): Promise<void> =>
    client.post(`/v1/modules/${moduleId}/residentInformation`, data),

  getDwollaCustomer: (): Promise<IResponse<IDwollaCustomer>> =>
    client.get("/v1/dwolla/customer"),

  getDwollaToken: (data: object): Promise<IResponse<{ token: string }>> =>
    client.post("/v1/dwolla/token", data),

  getPlaidToken: (): Promise<{ linkToken: string }> =>
    client.post("/v2/plaid/token", {
      plaidRedirectUri: config.appUrl + config.plaidOAuthCallbackUrl,
    }),

  // does not need redirectUri because manual verification doesn't need redirects 
  getPlaidTokenForPaymentMethod: ({
    id,
  }: {
    id: string;
  }): Promise<{ linkToken: string }> =>
    client.post(`/v2/paymentMethods/${id}/token`, {}),

  getPaymentMethods: (): Promise<IResponse<IPaymentMethod[]>> =>
    client.get("/v1/paymentMethods"),

  addPaymentMethod: (
    data: IAddPaymentMethod
  ): Promise<IResponse<IPaymentMethod>> =>
    client.post("/v1/paymentMethods", data),

  removePaymentMethod: ({ id }: { id: string }): Promise<void> =>
    client.del(`/v1/paymentMethods/${id}`),

  verifyPaymentMethod: ({
    id,
    verificationStatus,
  }: {
    id: string;
    verificationStatus: VerificationStatus;
  }): Promise<IResponse<IPaymentMethod>> =>
    client.post(`/v2/paymentMethods/${id}/verify`, {
      verificationStatus,
    }),

  setDwollaCustomer: ({ customerId }: { customerId: string }): Promise<void> =>
    client.post(`/v1/dwolla/customer`, {
      dwollaExternalCustomerId: customerId,
    }),

  getTransactions: (): Promise<IResponse<ITransactionResponse[]>> =>
    client.get(`/v1/transactions`),

  cancelTransaction: (id: string): Promise<void> =>
    client.post(`/v1/transactions/${id}/cancel`, {}),

  getAutopayByLease: (leaseId: string): Promise<IResponse<IAutopay>> =>
    client.get(`/v1/lease/${leaseId}/autopay`, {}),

  createAutopay: (data: IAutopayRequest): Promise<IResponse<IAutopay>> =>
    client.post("/v1/autopay", data),

  terminateAutopay: (id: string): Promise<IResponse<void>> =>
    client.put(`/v1/autopay/${id}/terminate`, {}),

  getLeasePermissions: (): Promise<IResponse<ILeasePermissionResponse>> =>
    client.get("/v1/lease/permissions", {}),

  createModule: (
    data: ICreateModuleRequest
  ): Promise<IResponse<IPaymentModule>> =>
    client.post("/v1/modules/rent", data),

  getUserConsent: (
    key: UserConsentKey
  ): Promise<IResponse<IUserConsentResponse>> =>
    client.get(`/v1/user/consent/${key}`),

  updateUserConsent: (
    key: UserConsentKey,
    action: UserConsentAction
  ): Promise<IResponse<IUserConsentResponse>> =>
    client.post(`/v1/user/consent/${key}/status/${action}`, {}),
};

export default api;
