/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import { useHistory } from "react-router-dom";
import ActionButton from "../../components/action-button";
import { Head, Header } from "../../components/header";

import styles from "./canceled.module.css";

export default function AutopayAmount() {
  const history = useHistory();

  async function action() {
    history.push("/?autopay");
  }

  return (
    <div className={styles.pay}>
      <Head>
        <Header>Your autopay has been cancelled.</Header>
      </Head>
      <div className={styles.content}>
        Please re-set your autopay to make sure that the balance due is paid on
        time without incurring any late fee.
      </div>

      <ActionButton onClick={action}>Done</ActionButton>
    </div>
  );
}
