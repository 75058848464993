import * as React from "react";
import cx from "classnames";

import styles from "./settings.module.css";

export interface ISetting {
  id: string;
  name: string;
  description?: string;
  content: React.ReactNode;
  onClick?: () => void;
}

interface IProps {
  settings: ISetting[];
}

export default function Settings({ settings }: IProps) {
  const handleOnClick = (setting: ISetting) => {
    if (!setting.onClick) {
      return;
    }
    setting.onClick();
  };

  return (
    <>
      {settings.map((s) => (
        <div
          key={s.id}
          className={cx(styles.item, s.onClick ? styles.clickable : null)}
          onClick={() => handleOnClick(s)}
        >
          <div className={styles.row}>
            <div className={styles.name}>{s.name}</div>
            <div className={styles.content}>{s.content}</div>
          </div>
          {s.description && (
            <div className={styles.subtitle}>{s.description}</div>
          )}
        </div>
      ))}
    </>
  );
}
