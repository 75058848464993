import * as React from "react";
import cx from "classnames";

import styles from "./status.module.scss";

interface IProps {
  text: string;
  className?: string;
  pending?: boolean;
  error?: boolean;
  completed?: boolean;
}

export default function Status({
  pending,
  error,
  completed,
  text,
  className,
}: IProps) {
  return (
    <span
      className={cx(
        styles.status,
        completed ? styles.completed : undefined,
        error ? styles.error : undefined,
        pending ? styles.pending : undefined,
        className
      )}
    >
      {text}
    </span>
  );
}
