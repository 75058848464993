import * as React from "react";
import { Formik, FormikProps } from "formik";
import ActionButton from "../action-button";
import Input from "../input";
import Checkbox from "../checkbox";

import { centsToDollars } from "../../helpers/common";

import schema from "./amount.schema";

import styles from "./amount.module.css";

export interface IValues {
  amount: number;
  isMax: boolean;
}

interface IProps {
  amount?: number;
  isMax?: boolean;
  totalAmount: number;
  onSubmit: (values: IValues) => void;
}

export default function Amount({
  amount,
  isMax,
  totalAmount,
  onSubmit,
}: IProps) {
  async function action(values: IValues) {
    onSubmit(values);
  }

  return (
    <Formik<IValues>
      initialValues={{
        amount: (amount || 0) / 100,
        isMax: isMax || false,
      }}
      validateOnBlur
      validateOnChange
      validationSchema={schema}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={action}
    >
      {({
        handleSubmit,
        setFieldValue,
        handleChange,
        handleBlur,
        isValid,
        values,
        errors,
        touched,
      }: FormikProps<IValues>) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.item}>
            <Input
              prefix="$"
              type="number"
              name="amount"
              placeholder={centsToDollars(totalAmount, {
                skipDollarSign: true,
                showCents: true,
              })}
              className={styles.inputContainer}
              inputClassName={styles.input}
              prefixClassName={styles.inputPrefix}
              disabled={values.isMax}
              value={values.amount || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.amount && errors.amount && (
              <div className={styles.errorMessage}>{errors.amount}</div>
            )}
          </div>
          <Checkbox
            left
            blue
            name="maxAmount"
            label="Set to the maximum amount due"
            checked={values.isMax}
            onChange={() => {
              const newIsMax = !values.isMax;
              setFieldValue("isMax", newIsMax);
              if (newIsMax) {
                setTimeout(() => setFieldValue("amount", 0), 100);
              }
            }}
          />
          <ActionButton type="submit" small disabled={!isValid}>
            Set
          </ActionButton>
        </form>
      )}
    </Formik>
  );
}
