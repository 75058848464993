/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { centsToDollars } from "../../helpers/common";
import ActionButton from "../../components/action-button";
import { ILease, IPaymentModule } from "../../resources/lease/types";
import { IStore } from "../../resources/types";
import { ReactComponent as CheckIcon } from "../../components/icons/checkmark-white.svg";

import styles from "./confirm.module.css";

export default function Confirm() {
  const { moduleId } = useParams<{ moduleId: string }>();
  const history = useHistory();

  const lease = useSelector((state: IStore) => state.lease?.lease) as ILease;

  function action() {
    history.replace("/");
  }

  const task = lease.modules.find((t) => t.id === moduleId) as IPaymentModule;
  if (!task) {
    return null;
  }

  return (
    <div className={styles.confirm}>
      <CheckIcon className={styles.icon} />
      <h1>Payment pending</h1>
      <p>
        Your payment of <b>{centsToDollars(task.totalAmount)}</b> has been
        initiated and may take up to 5 business days to complete.
      </p>
      <p>
        You will receive email updates about the status of this payment, and may
        also track it in the payment history.
      </p>
      <ActionButton
        containerClassName={styles.btnContainer}
        className={styles.btn}
        onClick={action}
      >
        Continue
      </ActionButton>
    </div>
  );
}
