import * as React from "react";
import { useSelector } from "react-redux";
import Header from "./header";
import Spinner from "../../components/spinner";
import Container from "../../components/container";
import { IStore } from "../../resources/types";

import { ILease } from "../../resources/lease/types";

import styles from "./layout.module.css";

interface IProps {
  children: React.ReactChild | React.ReactChild[];
  showBackButton?: boolean;
  backUrl?: string;
}

export default function Layout({
  children,
  showBackButton = true,
  backUrl,
}: IProps) {
  const isLoading = useSelector((state: IStore) => state.common.isLoading);
  const lease = useSelector((state: IStore) => state.lease?.lease) as ILease;

  if (!lease) {
    return <Spinner />;
  }

  return (
    <div id="layout">
      {showBackButton && (
        <Header showBackButton={showBackButton} backUrl={backUrl} />
      )}
      <Container className={styles.container}>{children}</Container>
      {isLoading && <Spinner />}
    </div>
  );
}
