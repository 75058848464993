import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie11";
import "./styles/vars.css";
import "./index.css";
import config from "./config";
import reportWebVitals from "./reportWebVitals";

import App from "./App";

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
}

renderApp();

if (module.hot) {
  module.hot.accept("./routes", () => {
    renderApp();
  });
}

if (config.isDev || config.isSandbox || config.isLocal) {
  /* eslint-disable-next-line */
  reportWebVitals(console.log);
}
