import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactHeap from "./modules/heap";
import Spinner from "./components/spinner";
import { leaseActions } from "./resources/lease/lease.slice";
import { IStore } from "./resources/types";
import config from "./config";

interface IProps {
  children: React.ReactChild | React.ReactChild[];
}

function Initialize({ children }: IProps) {
  const lease = useSelector((state: IStore) => state.lease.lease);
  const dwollaCustomer = useSelector(
    (state: IStore) => state.lease.dwollaCustomer
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!lease) {
      dispatch(leaseActions.fetchLease({}));
    }
  }, [dispatch, lease]);

  React.useEffect(() => {
    if (dwollaCustomer === undefined) {
      dispatch(leaseActions.fetchDwollaCustomer({}));
    }
  }, [dispatch, dwollaCustomer]);

  React.useEffect(() => {
    ReactHeap.initialize(config.heapId);
  }, []);

  if (!lease || !lease.id || dwollaCustomer === undefined) {
    return <Spinner />;
  }

  return <>{children}</>;
}

export default Initialize;
