/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAuthState } from "./types";

export const initialState: IAuthState = {};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(
      state: IAuthState,
      action: PayloadAction<{ email: string; password: string }>
    ) {},
    resetPassword(
      state: IAuthState,
      action: PayloadAction<{ email: string; captcha: string }>
    ) {},
    logout() {},
    checkAuth() {},
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
