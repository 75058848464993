import * as React from "react";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import moment, { Moment } from "moment-timezone";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Head, Header } from "../../components/header";
import Balance from "../../components/balance";

import Transaction from "./transaction";

import {
  ITransaction,
  ILease,
  IPaymentModule,
} from "../../resources/lease/types";
import {
  isNegativeBalance,
  isPositiveBalance,
  needToCoverBalance,
} from "../../resources/lease/lease.service";
import { leaseActions } from "../../resources/lease/lease.slice";
import { IStore } from "../../resources/types";

import styles from "./history.module.css";

const getPayments = (transactions: ITransaction[] | undefined): IHistory[] => {
  const payments: IHistory[] = [];
  if (!transactions) {
    return payments;
  }
  const transactionsGrouped = groupBy(transactions, (x) =>
    moment(x.creationTimestamp).startOf("month").toDate().getTime()
  );
  Object.keys(transactionsGrouped).forEach((key: string) => {
    payments.push({
      date: moment(+key),
      transactions: orderBy(
        transactionsGrouped[key],
        (x) => x.creationTimestamp,
        "desc"
      ),
    });
  });
  return orderBy(payments, (x) => x.date, "desc");
};

interface IHistory {
  date: Moment;
  transactions: ITransaction[];
}

export default function History() {
  const history = useHistory();
  const dispatch = useDispatch();

  const transactions = useSelector(
    (state: IStore) => state.lease?.transactions
  );
  const isTransactionsLoading = useSelector(
    (state: IStore) => state.lease?.loading.transactions
  );
  const lease = useSelector((state: IStore) => state.lease?.lease) as ILease;

  const payments = React.useMemo(
    () => getPayments(transactions),
    [transactions]
  );

  React.useLayoutEffect(() => {
    dispatch(leaseActions.fetchTransactions());
  }, [dispatch]);

  const goToDetails = (transaction: ITransaction) => {
    history.push(`/history/${transaction.id}`);
  };

  const payNow = (module: IPaymentModule) => {
    history.push(`/payments/${module.paymentType}`);
  };

  const hasTransactions = transactions && transactions.length > 0;

  const negativeBalanceModules = lease.modules.filter(
    isNegativeBalance
  ) as IPaymentModule[];
  const positiveBalanceModules = lease.modules.filter(
    isPositiveBalance
  ) as IPaymentModule[];
  const moduleToCoverBalance = sortBy(negativeBalanceModules, (x) => [
    x.dueDate,
    x.sequence,
  ]).find((x) => needToCoverBalance(x));

  return (
    <div className={styles.container}>
      <Head>
        <Header>Payment history</Header>
      </Head>

      {negativeBalanceModules.length > 0 && hasTransactions && (
        <Balance
          amount={sumBy(negativeBalanceModules, (x) => x.totalAmount)}
          action={
            moduleToCoverBalance
              ? () => payNow(moduleToCoverBalance)
              : undefined
          }
        />
      )}
      {negativeBalanceModules.length === 0 &&
        positiveBalanceModules.length > 0 &&
        hasTransactions && (
          <Balance
            positive
            amount={sumBy(positiveBalanceModules, (x) => x.totalAmount)}
          />
        )}

      <div className={styles.content}>
        {!hasTransactions && !isTransactionsLoading && (
          <div className={styles.noItems}>There is no payment history yet.</div>
        )}
        {payments.map((payment) => (
          <div key={payment.date.toString()} className={styles.group}>
            <div className={styles.title}>
              {moment(payment.date).format("MMMM YYYY")}
            </div>
            <div className={styles.payments}>
              {payment.transactions.map((transaction) => (
                <Transaction
                  className={styles.payment}
                  key={transaction.id}
                  transaction={transaction}
                  onClick={goToDetails}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
