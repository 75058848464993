import { PayloadAction } from "@reduxjs/toolkit";
import {
  all,
  take,
  takeEvery,
  put,
  call,
  delay,
  select,
} from "redux-saga/effects";
import { push } from "connected-react-router";
import moment from "moment";
import orderBy from "lodash/orderBy";

import { leaseActions } from "./lease.slice";
import { commonActions } from "../common/common.slice";
import {
  ModuleType,
  IAddPaymentMethod,
  IPaymentMethod,
  ISubmitModule,
  IDwollaCustomer,
  ITransactionResponse,
  ILeaseResponse,
  VerificationStatus,
  IAutopayRequest,
  IAutopay,
  ILeaseBalance,
  ILeasePermissionResponse,
  ICreateModule,
  IPaymentModule,
  LeasePermission,
  UserConsentKey,
  UserConsentAction,
} from "./types";
import { IResponse, IStore } from "../types";
import { setPlaidLinkToken } from "../../helpers/storage";
import api from "./lease.api";

export function* fetchLeaseWorker(
  data: PayloadAction<{ skipLoading?: boolean }>
) {
  try {
    if (!data.payload.skipLoading) {
      yield put(commonActions.setIsLoading(true));
    }

    const leasePermissions: LeasePermission[] = yield select(
      (state: IStore) => state.lease.leasePermissions
    );

    // if no permission, fetch both lease and permissions
    if (!leasePermissions?.length) {
      const [leaseResponse, leasePermissionResponse]: [
        IResponse<ILeaseResponse>,
        IResponse<ILeasePermissionResponse>
      ] = yield all([call(api.getLease), call(api.getLeasePermissions)]);
      yield put(
        leaseActions.setLeasePermissions(
          leasePermissionResponse.payload.message
        )
      );
      yield put(leaseActions.setLease(leaseResponse));
    } else {
      const response: IResponse<ILeaseResponse> = yield call(api.getLease);
      yield put(leaseActions.setLease(response));
    }

    if (!data.payload.skipLoading) {
      yield put(commonActions.setIsLoading(false));
    }
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* fetchLeaseBalanceWorker(
  data: PayloadAction<{ year: number; month: number }>
) {
  const { year, month } = data.payload;
  try {
    const response: IResponse<ILeaseBalance> = yield call(
      api.getLeaseBalance,
      year,
      month
    );
    const leaseBalance = response.payload.message;
    leaseBalance.dueDate = moment()
      // month is 0 based, but since we need the next month we use what we get from backend
      .month(leaseBalance.period.month)
      .year(leaseBalance.period.year)
      .startOf("month")
      .toDate();
    // Charges order is not guaranteed
    leaseBalance.charges = orderBy(leaseBalance.charges, "amount", "desc");

    yield put(leaseActions.setLeaseBalance(leaseBalance));
  } catch (err: any) {
    yield put(leaseActions.setLeaseBalance(null));

    if (err.data?.payload?.message?.error === "LEASE_BALANCE_NOT_FOUND") {
      return;
    }

    yield put(commonActions.handleError(err));
  }
}

export function* fetchPaymentMethodsWorker() {
  try {
    yield put(commonActions.setIsLoading(true));

    const response: IResponse<IPaymentMethod[]> = yield call(
      api.getPaymentMethods
    );
    yield put(leaseActions.setPaymentMethods(response));
    yield put(commonActions.setIsLoading(false));
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* updateDwollaUserConsentAndCustomerIdWorker(
  data: PayloadAction<{
    customerId?: string;
    delayInMs?: number;
  }>
) {
  try {
    yield put(commonActions.setIsLoading(true));
    yield put(
      leaseActions.setLoading({
        homePage: true,
      })
    );

    yield call(api.updateUserConsent,
      UserConsentKey.DWOLLA_TOS,
      UserConsentAction.ACCEPT
    );
    if (data.payload.customerId) {
      yield call(api.setDwollaCustomer, {
        customerId: data.payload.customerId,
      });
    }
    yield put(
      leaseActions.fetchDwollaCustomer({ delayInMs: data.payload.delayInMs })
    );

    yield take(leaseActions.setDwollaCustomer);
    yield put(commonActions.setIsLoading(false));
    yield put(
      leaseActions.setLoading({
        homePage: false,
      })
    );
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* fetchDwollaCustomerWorker(
  data: PayloadAction<{ delayInMs?: number; redirectTo?: string }>
) {
  try {
    yield put(commonActions.setIsLoading(true));

    if (data.payload.delayInMs) {
      yield delay(data.payload.delayInMs);
    }

    const response: IResponse<IDwollaCustomer> = yield call(
      api.getDwollaCustomer
    );
    yield put(leaseActions.setDwollaCustomer(response));

    yield put(commonActions.setIsLoading(false));

    if (data.payload.redirectTo) {
      yield put(push(data.payload.redirectTo));
    }
  } catch (err: any) {
    if (
      err?.data?.payload?.message?.error === "NO_DWOLLA_CUSTOMER_FOR_RESIDENT"
    ) {
      yield put(leaseActions.setEmptyDwollaCustomer());
      yield put(commonActions.setIsLoading(false));
    } else {
      yield put(commonActions.handleError(err));
    }
  }
}

export function* confirmInformationWorker(
  data: PayloadAction<{ moduleId: string }>
) {
  try {
    yield put(commonActions.setIsLoading(true));

    yield call(api.updateLeaseResidentInfo, {
      moduleId: data.payload.moduleId,
      data: {
        checkInfoModuleExtraData: {
          confirmedCorrect: true,
        },
      },
    });
    yield put(leaseActions.fetchLease({}));

    yield take(leaseActions.setLease);

    yield put(push("/verify"));
    yield put(commonActions.setIsLoading(false));
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* addPaymentMethodWorker(
  data: PayloadAction<IAddPaymentMethod>
) {
  try {
    yield put(commonActions.setIsLoading(true));

    yield call(api.addPaymentMethod, data.payload);
    yield put(leaseActions.setPlaidToken({ token: undefined }));
    setPlaidLinkToken(undefined);
    yield put(leaseActions.fetchPaymentMethods());
  } catch (err: any) {
    yield put(leaseActions.setPlaidToken({ token: undefined }));
    setPlaidLinkToken(undefined);
    yield put(commonActions.handleError(err));
  }
}

export function* removePaymentMethodWorker(
  data: PayloadAction<{ id: string }>
) {
  try {
    yield put(commonActions.setIsLoading(true));

    yield call(api.removePaymentMethod, data.payload);
    yield put(leaseActions.setRemovePaymentMethod({ id: data.payload.id }));
    yield put(leaseActions.fetchPaymentMethods());
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* verifyPaymentMethodWorker(
  data: PayloadAction<{ id: string; verificationStatus: VerificationStatus }>
) {
  try {
    yield put(commonActions.setIsLoading(true));

    yield call(api.verifyPaymentMethod, data.payload);
    yield put(leaseActions.setVerifyPaymentMethtod({ id: data.payload.id }));
    yield put(leaseActions.fetchPaymentMethods());
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* fetchPlaidTokenWorker() {
  try {
    yield put(commonActions.setIsLoading(true));
    const response: IResponse<{ linkToken: string }> = yield call(
      api.getPlaidToken
    );
    const token = response.payload.message.linkToken;

    setPlaidLinkToken(token);
    yield put(leaseActions.setPlaidToken({ token }));

    yield put(commonActions.setIsLoading(false));
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* fetchPlaidTokenForPaymentMethodWorker(
  data: PayloadAction<{ id: string }>
) {
  try {
    yield put(commonActions.setIsLoading(true));

    const response: IResponse<{ linkToken: string }> = yield call(
      api.getPlaidTokenForPaymentMethod,
      {
        id: data.payload.id,
      }
    );
    const token = response.payload.message.linkToken;
    yield put(leaseActions.setPlaidTokenForPaymentMethod({ token }));

    yield put(commonActions.setIsLoading(false));
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* submitModuleWorker(data: PayloadAction<ISubmitModule>) {
  try {
    yield put(commonActions.setIsLoading(true));

    const extraData = {
      sourcePaymentMethodId: data.payload.paymentMethodId,
    };
    const req = {
      moduleId: data.payload.moduleId,
      data: {
        ...(data.payload.type === ModuleType.RENT && {
          rentModuleExtraData: extraData,
        }),
        ...(data.payload.type === ModuleType.DEPOSIT && {
          depositModuleExtraData: extraData,
        }),
      },
    };
    yield call(api.updateLeaseResidentInfo, req);
    yield put(leaseActions.setSubmitModule());
    yield put(leaseActions.fetchLease({}));

    yield put(push(`/payments/${data.payload.moduleId}/success`));

    yield put(commonActions.setIsLoading(false));
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* fetchTransactionsWorker() {
  try {
    yield put(commonActions.setIsLoading(true));

    const response: IResponse<ITransactionResponse[]> = yield call(
      api.getTransactions
    );
    yield put(leaseActions.setTransactions(response));

    yield put(commonActions.setIsLoading(false));
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* cancelTransactionWorker(data: PayloadAction<{ id: string }>) {
  try {
    yield put(commonActions.setIsLoading(true));

    yield call(api.cancelTransaction, data.payload.id);
    yield put(leaseActions.fetchTransactions());
    yield put(leaseActions.fetchLease({}));

    yield take(leaseActions.setTransactions);

    yield put(commonActions.setIsLoading(false));
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* fetchAutopayWorker(
  data: PayloadAction<{ leaseId: string; skipLoading?: boolean }>
) {
  try {
    if (!data.payload.skipLoading) {
      yield put(commonActions.setIsLoading(true));
    }

    const response: IResponse<IAutopay> = yield call(
      api.getAutopayByLease,
      data.payload.leaseId
    );
    yield put(leaseActions.setAutopay(response.payload.message));

    if (!data.payload.skipLoading) {
      yield put(commonActions.setIsLoading(false));
    }
  } catch (err: any) {
    yield put(leaseActions.setAutopay(null));

    if (err.data?.payload?.message?.error === "AUTOPAY_NOT_FOUND") {
      if (!data.payload.skipLoading) {
        yield put(commonActions.setIsLoading(false));
      }
      return;
    }

    yield put(commonActions.handleError(err));
  }
}

export function* createAutopayWorker(
  data: PayloadAction<{ autopay: IAutopayRequest }>
) {
  const { autopay } = data.payload;
  try {
    const response: IResponse<IAutopay> = yield call(
      api.createAutopay,
      autopay
    );
    yield put(leaseActions.setAutopay(response.payload.message));

    yield put(push("/autopay/success"));
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* cancelAutopayWorker(data: PayloadAction<{ id: string }>) {
  try {
    yield call(api.terminateAutopay, data.payload.id);
    yield put(leaseActions.setAutopay(null));
    yield put(push("/autopay/cancel/success"));
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* createModuleWorker(data: PayloadAction<ICreateModule>) {
  const { amount, paymentMethodId } = data.payload;

  try {
    yield put(commonActions.setIsLoading(true));

    const response: IResponse<IPaymentModule> = yield call(api.createModule, {
      amount,
    });
    const { id, type } = response.payload.message;
    yield put(
      leaseActions.submitModule({
        moduleId: id,
        paymentMethodId,
        type,
      })
    );
  } catch (err: any) {
    yield put(commonActions.handleError(err));
  }
}

export function* leaseWatcher() {
  yield all([
    takeEvery(leaseActions.fetchLease, fetchLeaseWorker),
    takeEvery(leaseActions.fetchLeaseBalance, fetchLeaseBalanceWorker),
    takeEvery(leaseActions.fetchPaymentMethods, fetchPaymentMethodsWorker),
    takeEvery(leaseActions.fetchDwollaCustomer, fetchDwollaCustomerWorker),
    takeEvery(leaseActions.confirmInformation, confirmInformationWorker),
    takeEvery(
      leaseActions.updateDwollaUserConsentAndCustomerId,
      updateDwollaUserConsentAndCustomerIdWorker
    ),
    takeEvery(leaseActions.addPaymentMethod, addPaymentMethodWorker),
    takeEvery(leaseActions.removePaymentMethod, removePaymentMethodWorker),
    takeEvery(leaseActions.verifyPaymentMethod, verifyPaymentMethodWorker),
    takeEvery(leaseActions.fetchPlaidToken, fetchPlaidTokenWorker),
    takeEvery(
      leaseActions.fetchPlaidTokenForPaymentMethod,
      fetchPlaidTokenForPaymentMethodWorker
    ),
    takeEvery(leaseActions.submitModule, submitModuleWorker),
    takeEvery(leaseActions.fetchTransactions, fetchTransactionsWorker),
    takeEvery(leaseActions.cancelTransaction, cancelTransactionWorker),
    takeEvery(leaseActions.fetchAutopay, fetchAutopayWorker),
    takeEvery(leaseActions.createAutopay, createAutopayWorker),
    takeEvery(leaseActions.cancelAutopay, cancelAutopayWorker),
    takeEvery(leaseActions.createModule, createModuleWorker),
  ]);
}
