export default {
  isLocal: process.env.REACT_APP_ENV === "LOCAL",
  isSandbox: process.env.REACT_APP_ENV === "SANDBOX",
  isDev: process.env.REACT_APP_ENV === "DEV",
  isQa: process.env.REACT_APP_ENV === "QA",
  isStaging: process.env.REACT_APP_ENV === "STAGING",
  isProduction: process.env.REACT_APP_ENV === "PRODUCTION",
  appUrl: process.env.REACT_APP_URL,
  apiUrl:
    process.env.REACT_APP_LATCH_API_URL ||
    "https://api-resident.sandbox.latchaccess.com",
  refreshTokenUrl: "/v1/auth/token/refresh",
  gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID || "UA-158110460-3",
  recaptchaKey:
    process.env.REACT_APP_RECAPTCHA_KEY ||
    "6LcUg1oUAAAAAN6G6EixOSmD_sfKEQcjmE01akyI",
  dwolla: {
    environment: process.env.REACT_APP_DWOLLA_ENV || "sandbox",
    url: process.env.REACT_APP_DWOLLA_URL || "https://api-sandbox.dwolla.com",
    termsOfService: "https://www.dwolla.com/legal/tos/",
    privacyPolicy: "https://www.dwolla.com/legal/privacy/",
  },
  supportPage: "https://support.latch.com/",
  termsOfService: "https://www.latch.com/terms-of-service",
  privacyPolicy: "https://www.latch.com/privacy-policy",
  heapId: process.env.REACT_APP_HEAP_ID || "709472028",
  appStoreUrl: "https://apps.apple.com/us/app/latch-app/id1062355375",
  plaidOAuthCallbackUrl: "/oauth/plaid/callback",
} as const;
