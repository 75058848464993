import * as React from "react";
import cx from "classnames";

import { ReactComponent as ArrowIcon } from "../../components/icons/arrow-black.svg";
import { ReactComponent as SuccessIcon } from "../../components/icons/success.svg";
import { ReactComponent as WarningIcon } from "../../components/icons/warning.svg";

import styles from "./task.module.scss";
import { TaskStatus } from "../../resources/lease/types";
import Status from "../../components/status";

interface IProps {
  title: string;
  description?: string;
  status?: TaskStatus;
  onClick?: () => void;
}

export default function Task({ title, description, status, onClick }: IProps) {
  const onTaskClick = () => {
    if (
      status === TaskStatus.DISABLED ||
      status === TaskStatus.COMPLETED ||
      status === TaskStatus.ERROR
    ) {
      return null;
    }
    return onClick?.();
  };

  const renderIcon = () => {
    if (status === TaskStatus.COMPLETED) {
      return <SuccessIcon className={cx(styles.icon, styles.roundIcon)} />;
    }
    if (status === TaskStatus.WARNING || status === TaskStatus.ERROR) {
      return <WarningIcon className={cx(styles.icon, styles.roundIcon)} />;
    }

    return <ArrowIcon className={styles.icon} />;
  };

  return (
    <div
      className={cx(
        styles.task,
        styles.simple,
        status === TaskStatus.DISABLED && styles.disabled,
        status === TaskStatus.COMPLETED && styles.completed,
        status === TaskStatus.WARNING && styles.warning,
        status === TaskStatus.ERROR && styles.error,
        status !== TaskStatus.COMPLETED &&
          status !== TaskStatus.DISABLED &&
          status !== TaskStatus.ERROR &&
          styles.clickable
      )}
      key={title}
      onClick={onTaskClick}
    >
      <div className={styles.label}>
        <h3>
          {title}
          {status === TaskStatus.PAYMENT_PENDING && (
            <Status pending text="Pending" className={styles.status} />
          )}
        </h3>
        <p>{description}</p>

        {renderIcon()}
      </div>
    </div>
  );
}
