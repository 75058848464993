/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import cx from "classnames";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ILease,
  IDwollaCustomer,
  ModuleType,
  ModuleStatus,
  DwollaCustomerStatus,
} from "../../resources/lease/types";
import { IStore } from "../../resources/types";

import { Header, Head } from "../../components/header";
import LinkButton from "../../components/link-button";
import PaymentError from "../../components/payment-error";

import Task from "./task";
import VerifyTask from "./verify-task";

import { authActions } from "../../resources/auth/auth.slice";
import { leaseActions } from "../../resources/lease/lease.slice";

import { centsToDollars } from "../../helpers/common";

import { ReactComponent as ArrowIcon } from "../../components/icons/arrow-blue.svg";
import { ReactComponent as SuccessIcon } from "../../components/icons/success.svg";

import config from "../../config";

import styles from "./home.module.css";
import Spinner from "../../components/spinner";

export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();

  const lease = useSelector((state: IStore) => state.lease?.lease) as ILease;
  const dwollaCustomer = useSelector(
    (state: IStore) => state.lease.dwollaCustomer
  ) as IDwollaCustomer;
  const autopay = useSelector((state: IStore) => state.lease?.rent.autopay);
  const leaseBalance = useSelector(
    (state: IStore) => state.lease?.rent.balance
  );

  const isAutopayLoading = useSelector(
    (state: IStore) => state.lease?.loading.autopay
  );
  const isLeaseBalanceLoading = useSelector(
    (state: IStore) => state.lease?.loading.leaseBalance
  );

  const isLoading =
    leaseBalance === undefined || isAutopayLoading || isLeaseBalanceLoading;

  React.useEffect(() => {
    if (lease?.id && autopay === undefined) {
      dispatch(leaseActions.fetchAutopay({ leaseId: lease.id }));
    }
  }, [dispatch, lease?.id, autopay]);

  React.useEffect(() => {
    if (leaseBalance === undefined) {
      dispatch(
        leaseActions.fetchLeaseBalance({
          year: moment().year(),
          month: moment().month() + 1,
        })
      );
    }
  }, [dispatch, leaseBalance]);

  function onAutopayClick() {
    history.push("/autopay");
  }

  function onPayNowClick() {
    history.push("/paynow");
  }

  function onHistoryClick() {
    history.push("/history");
  }

  function onPaymentMethodClick() {
    history.push("/paymentMethod");
  }

  function onConfirmClick() {
    history.push("/confirm");
  }

  function onVerifyClick() {
    history.push("/verify");
  }

  const logout = () => {
    dispatch(authActions.logout());
  };

  const download = () => {
    window.open(config.appStoreUrl);
  };

  const onErrorClick = () => {
    history.push("/history");
  };

  const hasError = false;

  const showPayNow = false;

  const confirmTask = lease.modules.find(
    (x) => x.type === ModuleType.CHECKINFO
  );
  const isConfirmTaskCompleted =
    confirmTask && confirmTask.status === ModuleStatus.COMPLETED;
  const isDwollaVerified =
    dwollaCustomer &&
    dwollaCustomer.status === DwollaCustomerStatus.DWOLLA_VERIFIED;
  const isDwollaSuspended =
    dwollaCustomer &&
    (dwollaCustomer.status === DwollaCustomerStatus.DWOLLA_SUSPENDED ||
      dwollaCustomer.status === DwollaCustomerStatus.DWOLLA_DEACTIVATED);

  const isVerificationCompleted =
    isConfirmTaskCompleted && isDwollaVerified && !isDwollaSuspended;

  const content = (
    <>
      <div className={styles.home}>
        {!isConfirmTaskCompleted && (
          <Task
            title="Confirm Information"
            description="Review your information to set up your rent payments."
            onClick={onConfirmClick}
          />
        )}
        {isConfirmTaskCompleted &&
          !isDwollaVerified &&
          dwollaCustomer !== undefined && (
            <VerifyTask
              dwollaCustomer={dwollaCustomer}
              onClick={onVerifyClick}
            />
          )}
        {isVerificationCompleted && (
          <div className={styles.balanceContainer}>
            {hasError && (
              <PaymentError
                title="Your autopay failed to proceed."
                text="There was an insufficient balance."
                onClick={onErrorClick}
              />
            )}
            <div className={styles.balanceElement}>
              <div className={styles.sectionTitle}>Current balance</div>
              <div className={styles.amount}>
                {centsToDollars(leaseBalance?.amount || 0, { showCents: true })}
              </div>
              <div className={styles.text}>
                {leaseBalance ? (
                  <>
                    Your payment is due{" "}
                    {moment(leaseBalance.dueDate).format("MMMM DD, YYYY")}.
                  </>
                ) : (
                  <span className="green">There is no balance due.</span>
                )}
              </div>
              {autopay && (
                <div className={styles.scheduled}>
                  <SuccessIcon />
                  Auto payment scheduled.
                </div>
              )}
            </div>
            {leaseBalance && (
              <>
                <hr />
                <div className={styles.breakdown}>
                  <span>Here is the breakdown:</span>
                  <div className={styles.items}>
                    {leaseBalance.charges.map((item) => (
                      <div className={styles.item} key={item.id}>
                        <div className={styles.itemName}>
                          {item.description}
                        </div>
                        <div className={styles.itemAmount}>
                          {centsToDollars(item.amount, { showCents: false })}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {(!autopay || showPayNow) && (
              <div className={styles.tasks}>
                {showPayNow && (
                  <div className={styles.task} onClick={onPayNowClick}>
                    Pay now
                    <ArrowIcon className={styles.iconCenter} />
                  </div>
                )}
                {!autopay && (
                  <div className={styles.task} onClick={onAutopayClick}>
                    Set up autopay
                    <ArrowIcon className={styles.iconCenter} />
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {isVerificationCompleted && (
          <div className={styles.additional}>
            <div className={styles.sectionTitle}>Additional settings</div>
            {autopay && (
              <LinkButton className={styles.link} onClick={onAutopayClick}>
                Manage autopay
              </LinkButton>
            )}
            <LinkButton className={styles.link} onClick={onHistoryClick}>
              View payment history
            </LinkButton>
            <LinkButton className={styles.link} onClick={onPaymentMethodClick}>
              Manage payment method
            </LinkButton>
          </div>
        )}
      </div>
      <div className={styles.bottomContainer}>
        {false && (
          <LinkButton className={styles.bottomBtn} onClick={download}>
            Download Latch App
          </LinkButton>
        )}
        <LinkButton
          className={cx(styles.bottomBtn, styles.logout)}
          onClick={logout}
        >
          Sign out
        </LinkButton>
      </div>
    </>
  );

  return (
    <>
      <Head className={styles.head}>
        <Header>Pay rent</Header>
      </Head>

      {isLoading ? <Spinner /> : content}
    </>
  );
}
