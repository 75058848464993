import * as React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

interface IProps extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
  type?: "button" | "submit" | "reset";
}

export default function Button({
  className,
  children,
  type,
  ...props
}: IProps) {
  return (
    <button
      type={type || "button"}
      className={cx(styles.btn, className, {
        [styles.disabled]: props.disabled,
      })}
      {...props}
    >
      {children}
    </button>
  );
}
