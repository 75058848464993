import * as yup from "yup";

export default yup.object().shape({
  isMax: yup.boolean(),
  amount: yup.number().when("isMax", {
    is: true,
    then: yup.number().optional(),
    otherwise: yup
      .number()
      .min(100, "Minimum limit is $100.")
      .max(10000, "Maximum limit is $10,000.")
      .required("Limit is required"),
  }),
});
