function storageAvailable(type: "localStorage" | "sessionStorage"): boolean {
  let storage: Storage | undefined;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      !!storage &&
      storage.length !== 0
    );
  }
}

export function setItem(key: string, value: string) {
  if (storageAvailable("localStorage")) {
    window.localStorage.setItem(key, value);
  }
}

export function getItem(key: string) {
  if (storageAvailable("localStorage")) {
    return window.localStorage.getItem(key) || "";
  }
  return "";
}

export function removeItem(key: string) {
  if (storageAvailable("localStorage")) {
    window.localStorage.removeItem(key);
  }
}

export function getReturnUrl(): string | undefined {
  return getItem("RETURN_URL");
}

export function setReturnUrl(url: string | undefined): void {
  if (!url) {
    removeItem("RETURN_URL");
  } else {
    setItem("RETURN_URL", url);
  }
}

export function getPlaidLinkToken(): string | undefined {
  return getItem("PLAID_LINK_TOKEN");
}

export function setPlaidLinkToken(token: string | undefined): void {
  if (!token) {
    removeItem("PLAID_LINK_TOKEN");
  } else {
    setItem("PLAID_LINK_TOKEN", token);
  }
}
