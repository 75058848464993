/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ActionButton from "../../components/action-button";
import { Head, Header } from "../../components/header";

import { IStore } from "../../resources/types";

import styles from "./success.module.css";

export default function Success() {
  const history = useHistory();

  const autopay = useSelector((state: IStore) => state.lease?.rent.autopay);
  const paymentMethods = useSelector(
    (state: IStore) => state.lease?.paymentMethods || []
  );
  if (!autopay) {
    return null;
  }
  const paymentMethod = paymentMethods.find(
    (x) => x.id === autopay.paymentMethodId
  );
  if (!paymentMethod) {
    return null;
  }

  async function action() {
    history.push("/");
  }

  return (
    <div className={styles.pay}>
      <Head>
        <Header>Your autopay is set.</Header>
      </Head>
      <div className={styles.content}>
        The maximum amount due for your next payment will automatically be
        submitted beginning on{" "}
        <b>{moment(autopay.startDate).format("MMMM DD, YYYY")}</b> via{" "}
        <b>
          {paymentMethod.name} ending in {paymentMethod.mask}
        </b>{" "}
        until your lease expiration if not cancelled.
      </div>

      <ActionButton onClick={action}>Done</ActionButton>
    </div>
  );
}
