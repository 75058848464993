import { combineReducers, Action } from "redux";
import { connectRouter } from "connected-react-router";

import { Reducer } from "react";
import { History } from "history";
import { IStore } from "./types";
import { modalReducer } from "./modal/modal.slice";
import { leaseReducer } from "./lease/lease.slice";
import { authReducer } from "./auth/auth.slice";
import { commonReducer } from "./common/common.slice";

export default function createReducer(
  history: History
): Reducer<IStore | undefined, Action> {
  return combineReducers<any, any>({
    router: connectRouter(history),
    modal: modalReducer,
    lease: leaseReducer,
    auth: authReducer,
    common: commonReducer,
  });
}
