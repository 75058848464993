import client from "../../api";
import { IResponse } from "../types";

const api = {
  login: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<IResponse<object>> =>
    client.post(`/v1/auth/token`, {
      email,
      password,
      scope: "RESIDENT_WEB",
      softTokenOverride: true,
    }),

  resetPassword: ({
    email,
    captcha,
  }: {
    email: string;
    captcha: string;
  }): Promise<IResponse<object>> =>
    client.post(`/v1/user/password/reset`, {
      email,
      captcha,
      clearBrowserHistory: true,
    }),

  logout: (): Promise<IResponse<object>> => client.del(`/v1/auth/token`),
};

export default api;
