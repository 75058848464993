import * as React from "react";
import cx from "classnames";

import styles from "./container.module.css";

interface IProps {
  children: React.ReactChild | React.ReactChild[];
  className?: string;
}

export default ({ children, className }: IProps) => (
  <div id="container" className={cx(styles.container, className)}>
    {children}
  </div>
);

export const SafeAreaContainer = ({ children, className }: IProps) => (
  <div className={cx(styles.safeAreaContainer, className)}>{children}</div>
);
