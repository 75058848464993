import * as React from "react";

import { IDwollaCustomer } from "../../resources/lease/types";
import { mapDwollaStatusToTaskStatus } from "../../resources/lease/lease.service";

import verifyMessages from "../../helpers/verifyMessages";

import Task from "./task";

interface IProps {
  dwollaCustomer: IDwollaCustomer | null;
  onClick: () => void;
}

export default function VerifyTask({ dwollaCustomer, onClick }: IProps) {
  const { title, description } = verifyMessages({ dwollaCustomer });

  return (
    <Task
      title={title}
      description={description}
      status={mapDwollaStatusToTaskStatus(dwollaCustomer?.status)}
      onClick={onClick}
    />
  );
}
