/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { centsToDollars } from "../../helpers/common";
import ActionButton from "../../components/action-button";
import LinkButton from "../../components/link-button";
import { Head, Header } from "../../components/header";
import Spinner from "../../components/spinner";
import { leaseActions } from "../../resources/lease/lease.slice";
import { commonActions } from "../../resources/common/common.slice";
import {
  isPaymentMethodSelectable,
  isPaymentModule,
} from "../../resources/lease/lease.service";
import {
  ILease,
  IPaymentModule,
  LeasePermission,
  ModulePaymentStatus,
  ModuleType,
  PaymentType,
  TITLES,
} from "../../resources/lease/types";
import { IStore } from "../../resources/types";
import Settings, { ISetting } from "../../components/settings";
import AmountInput from "./components/amount-input/amount-input";

import styles from "./submit.module.css";

export default function Pay() {
  const { paymentType } =
    useParams<{ paymentType: ModuleType | PaymentType }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const lease = useSelector((state: IStore) => state.lease.lease) as ILease;
  const leasePermissions = useSelector(
    (state: IStore) => state.lease.leasePermissions
  );
  const paymentMethods = useSelector(
    (state: IStore) => state.lease.paymentMethods
  );
  const selectedPaymentMethodId = useSelector(
    (state: IStore) => state.lease.selectedPaymentMethodId
  );
  const selectedPaymentMethod = React.useMemo(
    () => paymentMethods?.find((x) => x.id === selectedPaymentMethodId),
    [paymentMethods, selectedPaymentMethodId]
  );
  const customRentAmount = useSelector(
    (state: IStore) => state.lease.customRentAmount
  );

  const [rentAmount, setRentAmount] = React.useState<number | undefined>();
  const isMonthlyRent = paymentType === PaymentType.MONTHLY;

  // We want to remember user entered amount when navigating to Payment method screen but not otherwise
  const memoCustomAmount = React.useRef(false);
  React.useEffect(
    () => () => {
      if (!memoCustomAmount.current) {
        dispatch(leaseActions.setCustomRentAmount({ amount: undefined }));
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(
      leaseActions.setCustomRentAmount({ amount: rentAmount || undefined })
    );
  }, [dispatch, rentAmount]);

  React.useEffect(() => {
    if (paymentMethods === undefined) {
      dispatch(leaseActions.fetchPaymentMethods());
    }
  }, [dispatch, paymentMethods]);

  const task: IPaymentModule | undefined = React.useMemo(() => {
    if (paymentType === PaymentType.MONTHLY) {
      return lease.modules
        .filter(
          (module) =>
            "paymentType" in module &&
            module.paymentType === PaymentType.MONTHLY
        )
        .slice(-1)[0] as IPaymentModule | undefined;
    }
    return lease.modules.find(
      (t) => t.type === paymentType && isPaymentModule(t)
    ) as IPaymentModule | undefined;
  }, [paymentType, lease]);

  const isLockedAmount =
    !isMonthlyRent || task?.paymentStatus === ModulePaymentStatus.COVER_BALANCE;

  React.useEffect(() => {
    if (!lease || !leasePermissions) {
      return;
    }

    if (
      (isMonthlyRent && !leasePermissions.includes(LeasePermission.RENT_PAY)) ||
      (!isMonthlyRent &&
        !leasePermissions.includes(LeasePermission.LEASE_PAY)) ||
      (!isMonthlyRent && !task)
    ) {
      history.push("/");
    }
  }, [task, lease, leasePermissions, isMonthlyRent, history]);

  if (!lease || paymentMethods === undefined) {
    return <Spinner />;
  }

  const action = async () => {
    if ((!isMonthlyRent && !task) || !selectedPaymentMethod) {
      return;
    }

    if (
      isMonthlyRent &&
      (!task || task.paymentStatus !== ModulePaymentStatus.COVER_BALANCE)
    ) {
      const amount = Math.floor((rentAmount || 0) * 100);
      if (lease.baseRentAmount && amount > lease.baseRentAmount) {
        dispatch(
          commonActions.setError({
            text: "Payment amount must not exceed amount due. Please adjust your amount and try again.",
          })
        );
        return;
      }

      dispatch(
        leaseActions.createModule({
          amount,
          paymentMethodId: selectedPaymentMethod.id,
        })
      );
    } else {
      dispatch(
        leaseActions.submitModule({
          paymentMethodId: selectedPaymentMethod.id,
          moduleId: task?.id || "",
          type: isMonthlyRent ? ModuleType.RENT : (paymentType as ModuleType),
        })
      );
    }
  };

  function editPayment() {
    memoCustomAmount.current = true;
    history.push(`/payments/${paymentType}/choose`);
  }

  const title = TITLES[paymentType];

  const isSubmitDisabled =
    !selectedPaymentMethod ||
    !isPaymentMethodSelectable(selectedPaymentMethod) ||
    (isMonthlyRent && !isLockedAmount && !rentAmount);

  const amountContent =
    !isMonthlyRent || isLockedAmount ? (
      centsToDollars(task?.totalAmount || 0)
    ) : (
      <AmountInput
        onChange={setRentAmount}
        initialValue={customRentAmount || (lease.baseRentAmount || 0) / 100}
      />
    );

  const settings: ISetting[] = [
    {
      id: "amount",
      name: "Amount",
      content: amountContent,
      description: isMonthlyRent
        ? `Monthly charges amount due ${centsToDollars(lease.baseRentAmount || 0)}`
        : undefined,
    },
    {
      id: "date",
      name: "Payment due",
      content: isMonthlyRent
        ? "First of every month"
        : moment(task?.dueDate).format("M/D/YY"),
    },
    {
      id: "pm",
      name: "Payment method",
      content: (
        <LinkButton className={styles.setup} onClick={editPayment}>
          {selectedPaymentMethod
            ? `${selectedPaymentMethod.name} ${selectedPaymentMethod.mask}`
            : "Add"}
        </LinkButton>
      ),
    },
  ];

  return (
    <div className={styles.pay}>
      <Head borderBottom>
        <Header>{title}</Header>
      </Head>

      <Settings settings={settings} />

      <div className={styles.note}>
        Please allow for up to 5 business days for your transaction to reach
        your recipient.
      </div>

      <ActionButton onClick={action} disabled={isSubmitDisabled}>
        Submit payment
      </ActionButton>
    </div>
  );
}
