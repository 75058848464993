import * as React from "react";
import { Provider, useSelector } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { IStore } from "./resources/types";
import configureStore from "./resources/store";
import Layout from "./pages/layout";
import Errors from "./pages/error/provider";

import { initialState as authInitialState } from "./resources/auth/auth.slice";
import { initialState as commonInitialState } from "./resources/common/common.slice";
import { initialState as leaseInitialState } from "./resources/lease/lease.slice";
import { initialState as modalInitialState } from "./resources/modal/modal.slice";
import { IStackItem } from "./resources/modal/types";

import { history } from "./history";

interface IProps {
  children: React.ReactChild | React.ReactChild[];
}

const initialState: IStore = {
  lease: leaseInitialState,
  auth: authInitialState,
  common: commonInitialState,
  modal: modalInitialState,
};
const store = configureStore(initialState, history);

function ReduxProviderWrap({ children }: IProps) {
  return <Provider store={store}>{children}</Provider>;
}

function ModalWrap() {
  const stack = useSelector((state: IStore) => state.modal.stack);
  return (
    <>
      {stack.map((modal: IStackItem) =>
        React.cloneElement(modal.component, {
          id: modal.id,
          show: modal.show,
          key: `MODAL_${modal.id}`,
          ...modal.component.props,
        })
      )}
    </>
  );
}

const App = () => (
  <ConnectedRouter history={history}>
    <Layout />
    <ModalWrap />
    <Errors />
  </ConnectedRouter>
);

export default function EnhancedApp() {
  return (
    <React.StrictMode>
      <ReduxProviderWrap>
        <App />
      </ReduxProviderWrap>
    </React.StrictMode>
  );
}
