import * as React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

interface IProps extends React.HTMLProps<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  prefixClassName?: string;
  label?: string;
  icon?: React.ReactElement;
  prefix?: string;
  autoComplete?: string;
}

export default function Input({
  className,
  inputClassName,
  prefixClassName,
  name,
  label,
  icon,
  placeholder,
  value,
  type,
  onChange,
  onBlur,
  autoComplete,
  readOnly,
  pattern,
  maxLength = 50,
  disabled = false,
  prefix,
}: IProps) {
  return (
    <div
      className={cx(
        styles.inputContainer,
        { [styles.hasIcon]: !!icon },
        className
      )}
    >
      {label && <label htmlFor={name}>{label}</label>}
      {icon &&
        React.cloneElement(icon, {
          ...icon.props,
          className: cx(icon.props.className, styles.icon),
        })}
      {prefix && (
        <span
          className={cx(
            styles.prefix,
            prefixClassName,
            disabled ? styles.disabled : undefined
          )}
        >
          {prefix}
        </span>
      )}
      <input
        name={name}
        placeholder={placeholder}
        value={value}
        type={type}
        pattern={pattern}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
        readOnly={!!readOnly}
        maxLength={maxLength}
        disabled={disabled}
        className={cx(styles.input, inputClassName)}
      />
    </div>
  );
}
