export enum PaymentMethodType {
  ACH = "ACH",
  CARD = "CARD",
}
export const PAYMENT_METHOD_TYPES: { [key: string]: string } = {
  [PaymentMethodType.ACH]: "ACH",
  [PaymentMethodType.CARD]: "Card",
};
export enum BankAccountType {
  CHECKING = "CHECKING",
  SAVINGS = "SAVINGS",
}
export enum VerificationStatus {
  INSTANTLY_VERIFIED = "INSTANTLY_VERIFIED",
  PENDING_AUTOMATIC_VERIFICATION = "PENDING_AUTOMATIC_VERIFICATION",
  PENDING_MANUAL_VERIFICATION = "PENDING_MANUAL_VERIFICATION",
  MANUALLY_VERIFIED = "MANUALLY_VERIFIED",
  AUTOMATICALLY_VERIFIED = "AUTOMATICALLY_VERIFIED",
  VERIFICATION_EXPIRED = "VERIFICATION_EXPIRED",
  VERIFICATION_FAILED = "VERIFICATION_FAILED",
}
export enum DwollaTokenType {
  CREATE_DWOLLA_CUSTOMER_FORM = "CREATE_DWOLLA_CUSTOMER_FORM",
  ACCOUNT_BALANCE = "ACCOUNT_BALANCE",
  DOCUMENT_UPLOAD = "DOCUMENT_UPLOAD",
  UPGRADE_UNVERIFIED_CUSTOMER = "UPGRADE_UNVERIFIED_CUSTOMER",
}
export enum DwollaCustomerStatus {
  DWOLLA_UNVERIFIED = "DWOLLA_UNVERIFIED",
  DWOLLA_VERIFIED = "DWOLLA_VERIFIED",
  DWOLLA_PENDING_KBA = "DWOLLA_PENDING_KBA",
  DWOLLA_PENDING_RETRY = "DWOLLA_PENDING_RETRY",
  DWOLLA_PENDING_DOCUMENT = "DWOLLA_PENDING_DOCUMENT",
  DWOLLA_PENDING_DOCUMENT_UPLOAD = "DWOLLA_PENDING_DOCUMENT_UPLOAD",
  DWOLLA_PENDING_DOCUMENT_REVIEW = "DWOLLA_PENDING_DOCUMENT_REVIEW",
  DWOLLA_DOCUMENT_REVIEW_FAILED = "DWOLLA_DOCUMENT_REVIEW_FAILED",
  DWOLLA_SUSPENDED = "DWOLLA_SUSPENDED",
  DWOLLA_DEACTIVATED = "DWOLLA_DEACTIVATED",
}
export enum TransactionStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
}
export enum PlaidErrorCode {
  TOO_MANY_VERIFICATION_ATTEMPTS = "TOO_MANY_VERIFICATION_ATTEMPTS",
}
export const TRANSACTION_STATUSES = {
  [TransactionStatus.PENDING]: "Pending",
  [TransactionStatus.COMPLETED]: "Completed",
  [TransactionStatus.CANCELLED]: "Cancelled",
  [TransactionStatus.FAILED]: "Failed",
};
export enum ModuleStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  AWAITING_VERIFICATION = "AWAITING_VERIFICATION",
}
export enum ModuleType {
  CHECKINFO = "CHECKINFO",
  RENT = "RENT",
  DEPOSIT = "DEPOSIT",
}

export enum PaymentType {
  RENT = "RENT",
  DEPOSIT = "DEPOSIT",
  MONTHLY = "MONTHLY",
}

export const TITLES: Record<ModuleType | PaymentType, string> = {
  [ModuleType.CHECKINFO]: "Confirm information",
  [PaymentType.DEPOSIT]: "Security deposit",
  [PaymentType.RENT]: "First month's rent",
  [PaymentType.MONTHLY]: "Monthly charges",
};

export enum ModulePaymentStatus {
  SUBMIT_PAYMENT = "SUBMIT_PAYMENT",
  RESUBMIT_PAYMENT = "RESUBMIT_PAYMENT",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_COMPLETED = "PAYMENT_COMPLETED",
  COVER_BALANCE = "COVER_BALANCE",
  BALANCE_COVER_PENDING = "BALANCE_COVER_PENDING",
  REFUND_PENDING = "REFUND_PENDING",
}

export enum PaymentScenario {
  PAYMENT = "PAYMENT",
  REFUND = "REFUND",
  BALANCE_COVER = "BALANCE_COVER",
}

export interface IDwollaCustomer {
  dwollaExternalCustomerId: string;
  status: DwollaCustomerStatus;
}

export interface IAutopay {
  id: string;
  leaseId: string;
  paymentMethodId: string;
  day?: number;
  frequency?: string;
  startDate: number;
  paymentCount?: number;
  amountLimit?: number;
}

export type IAutopayRequest = Omit<IAutopay, "id">;

export interface IPayNow {
  paymentDate: Date;
  amount?: number;
  isMax: boolean;
  paymentMethodId?: string;
}

export interface IRent {
  autopay?: IAutopay | null;
  balance?: ILeaseBalance | null;
  totalAmount: number;
  amount?: number;
  isMax: boolean;
  paymentMethodId?: string;
}

export interface ILoadingState {
  transactions: boolean;
  paymentMethods: boolean;
  lease: boolean;
  homePage: boolean;
  autopay: boolean;
  leaseBalance: boolean;
}

export interface ILeaseState {
  loading: ILoadingState;
  paymentMethods?: IPaymentMethod[];
  selectedPaymentMethodId?: string;
  lease?: ILease;
  customRentAmount?: number;
  transactions?: ITransaction[];
  dwollaCustomer?: IDwollaCustomer | null;
  error?: Error;
  success?: string;
  plaidLinkToken?: string;
  paynow: IPayNow;
  rent: IRent;
  leasePermissions?: LeasePermission[];
}

export interface IPaymentMethod {
  id: string;
  name: string;
  verificationStatus: VerificationStatus;
  type: PaymentMethodType;
  mask?: string;
}

export interface IResidentInfoData {
  checkInfoModuleExtraData?: {
    confirmedCorrect: boolean;
  };
  rentModuleExtraData?: {
    sourcePaymentMethodId: string;
  };
  depositModuleExtraData?: {
    sourcePaymentMethodId: string;
  };
  insuranceModuleExtraData?: {
    policyId: string;
  };
}

export interface IResident {
  id: string;
  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
}

export interface IFee {
  id: string;
  name: string;
  description: string;
  amount: number;
}

export interface IBuilding {
  id: string;
  name: string;
  street: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
}

export interface IAgent {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface IUnit {
  id: number;
  name: number;
}

export interface IPaymentModuleFields {
  amount: number;
  totalAmount: number;
  paymentStatus: ModulePaymentStatus;
  paymentType?: PaymentType;
}

export interface IModuleBase {
  id: string;
  title: string;
  description: string;
  status: ModuleStatus;
  sequence: number;
  fees: IFee[];
  type: ModuleType;
  canProceed: boolean;
}
export interface IModuleResponse extends IModuleBase {
  dueDate: number;
  completedAt: number;
}
export interface IModule extends IModuleBase {
  dueDate: Date;
  completedAt?: Date;
}
export interface IPaymentModuleBase extends IModuleBase, IPaymentModuleFields {}
export interface IPaymentModuleResponse
  extends IModuleResponse,
    IPaymentModuleFields {
  lastPaymentDate: number | null;
}
export interface IPaymentModule extends IModule, IPaymentModuleFields {
  lastPaymentDate: Date | null;
}

export type LeaseModuleBase = IPaymentModuleBase | IModuleBase;
export type LeaseModuleResponse = IPaymentModuleResponse | IModuleResponse;
export type LeaseModule = IPaymentModule | IModule;

export interface ILeaseBase {
  id: string;
  status: "PENDING";
  building: IBuilding;
  unit: IUnit;
  leasingAgent?: IAgent;
  guarantor?: IAgent;
  primaryResident: IResident;
  otherResidents: IResident[] | null;
}
export interface ILeaseResponse extends ILeaseBase {
  start: number;
  end: number;
  moveInDate: number;
  modules: LeaseModuleResponse[];
}
export interface ILease extends ILeaseBase {
  startDate: Date;
  endDate: Date;
  moveInDate: Date;
  totalAmount: number;
  balance: number;
  modules: LeaseModule[];
  baseRentAmount?: number;
  isActive?: boolean;
}

export interface IAddPaymentMethod {
  plaidAccountId: string;
  name: string;
  mask: string;
  token: string;
  type: PaymentMethodType;
  bankAccountType: BankAccountType;
  bankName?: string;
  verificationStatus: VerificationStatus;
}

export interface ISubmitModule {
  moduleId: string;
  paymentMethodId: string;
  type: ModuleType;
}

export interface ITransactionAccount {
  uuid: string;
  name: string;
  mask: string;
  ownerName: string;
}

export interface ITransactionBase {
  id: string;
  status: TransactionStatus;
  amount: number;
  paymentScenario: PaymentScenario;
  payerAccount: ITransactionAccount;
  payeeAccount: ITransactionAccount;
  cancellable?: boolean;
}
export interface ITransactionResponse extends ITransactionBase {
  creationTimestamp: number;
  completionTimestamp: number;
}
export interface ITransaction extends ITransactionBase {
  creationTimestamp: Date;
  completionTimestamp?: Date;
}

export const PAYMENT_METHOD_STATUSES: { [key: string]: string } = {
  PENDING_AUTOMATIC_VERIFICATION: "Pending",
  PENDING_MANUAL_VERIFICATION: "Pending",
  VERIFICATION_EXPIRED: "Expired",
  VERIFICATION_FAILED: "Failed",
};

export interface IPeriod {
  year: number;
  month: number;
}

export interface ILeaseBalanceCharge {
  id: string;
  balanceId: string;
  externalId: string;
  postedAt: number;
  amount: number;
  description: string;
}

export interface ILeaseBalance {
  id: string;
  leaseId: string;
  period: IPeriod;
  dueDate?: Date;
  amount: number;
  charges: ILeaseBalanceCharge[];
}

export enum LeasePermission {
  LEASE_PAY = "LEASE_PAY_ELIGIBLE",
  RENT_PAY = "RENT_PAY_ELIGIBLE",
  AUTO_PAY = "AUTO_PAY_ELIGIBLE",
}

export interface ILeasePermissionResponse {
  permissions: LeasePermission[];
}

export enum TaskStatus {
  DISABLED,
  IN_PROGRESS,
  PAYMENT_PENDING,
  COMPLETED,
  WARNING,
  ERROR,
}

export interface ITask {
  type: ModuleType | PaymentType;
  title: string;
  description: string;
  status: TaskStatus;
  module?: LeaseModule;
  onClick?: () => void;
}

export interface ICreateModuleRequest {
  amount: number;
}

export interface ICreateModule extends ICreateModuleRequest {
  paymentMethodId: string;
}

export enum UserConsentKey {
  DWOLLA_TOS = 'resdwollatos',
  STRIPE_TOS = 'resstripetos'
}

export enum UserConsentStatus {
  UNSET = 'UNSET',
  ACCEPTED = 'ACCEPTED',
  REVOKED = 'REVOKED'
}

export interface IUserConsentResponse {
  consentKey: UserConsentKey;
  status: UserConsentStatus;
  userId: string;
}

export enum UserConsentAction {
  ACCEPT = 'accept',
  REVOKE = 'revoke'
}
