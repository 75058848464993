import * as React from "react";
import { useSelector } from "react-redux";
import Spinner from "../../components/spinner";
import Container from "../../components/container";
import { IStore } from "../../resources/types";

import styles from "./layout.module.css";

interface IProps {
  children: React.ReactChild | React.ReactChild[];
}

export default function Layout({ children }: IProps) {
  const isLoading = useSelector((state: IStore) => state.common.isLoading);

  return (
    <div id="layout">
      <Container className={styles.container}>{children}</Container>
      {isLoading && <Spinner />}
    </div>
  );
}
