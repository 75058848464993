import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import PaymentMethods from "../../components/payment-methods";
import { Head, Header } from "../../components/header";
import { PaymentType } from "../../resources/lease/types";
import { leaseActions } from "../../resources/lease/lease.slice";

import styles from "./choose.module.css";

export default function PaymentMethod() {
  const { paymentType } = useParams<{ paymentType: PaymentType }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const onChange = (paymentMethodId: string) => {
    dispatch(leaseActions.selectPaymentMethod({ id: paymentMethodId }));
    history.push(`/payments/${paymentType}`);
  };

  return (
    <div className={styles.pay}>
      <Head>
        <Header>Payment method</Header>
      </Head>

      <div className={styles.content}>
        <PaymentMethods onChange={onChange} />
      </div>
    </div>
  );
}
