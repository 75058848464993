import { PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery, put } from "redux-saga/effects";
import { push } from "connected-react-router";

import { APIError } from "../../api";
import { IError } from "../../api/api.error";
import { REQUEST_TYPES } from "../../api/types";
import { leaseActions } from "../lease/lease.slice";
import { commonActions } from "./common.slice";

import { history } from "../../history";

import { setReturnUrl } from "../../helpers/storage";
import { authActions } from "../auth/auth.slice";

const LOGIN_URL = "/login";

const isUnauthorized = (apiErr: APIError) =>
  apiErr.status === 401 &&
  apiErr.data.header.messageType !== REQUEST_TYPES.CreateSession;

const isLeaseNotFound = (apiErr: APIError) =>
  apiErr.status === 400 &&
  (apiErr.data.payload.message as IError)?.error === "LEASE_NOT_FOUND";

export function* handleErrorWorker(data: PayloadAction<Error>) {
  if (data.payload instanceof APIError) {
    const apiErr = data.payload as APIError;
    if (isUnauthorized(apiErr)) {
      const { pathname } = history.location;
      if (pathname !== LOGIN_URL) {
        setReturnUrl(pathname);
      }
      yield put(commonActions.setIsLoading(false));
      yield put(leaseActions.logout());
      yield put(push(LOGIN_URL));

      return;
    }
    if (isLeaseNotFound(apiErr)) {
      yield put(authActions.logout());
    }
    const msg =
      typeof apiErr.data.payload.message === "string"
        ? apiErr.data.payload.message
        : (apiErr.data.payload.message as IError).error;
    yield put(commonActions.setError({ text: msg || "Some error occured" }));
    yield put(commonActions.setIsLoading(false));
    return;
  }
  yield put(commonActions.setError({ text: data.payload.message }));
}

export function* commonWatcher() {
  yield all([takeEvery(commonActions.handleError, handleErrorWorker)]);
}
