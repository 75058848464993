import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import APIError from "./api.error";
import { buildQueryString } from "../helpers/querystring";
import { getItem, setItem } from "../helpers/storage";
import { shouldRefreshToken } from "./api.helpers";
import {
  IQuery,
  IBody,
  IHeaders,
  RequestOptions,
  XSRF_TOKEN_KEY,
  XSRF_HEADER,
} from "./types";
import config from "../config";

export default class APIClient {
  private _baseUrl: string;

  private _defaultHeaders: IHeaders;

  private _xsrfToken: string | undefined;

  private _isRefreshing: boolean = false;

  private _refreshSubscribers: {
    resolve: () => void;
    reject: () => void;
  }[] = [];

  constructor(baseUrl: string) {
    this._baseUrl = baseUrl;
    this._defaultHeaders = {
      "Content-Type": "application/json",
    };
    this.createAxiosRefreshTokenInterceptor();
    this.createAxiosXsrfInterceptor();
  }

  createAxiosXsrfInterceptor() {
    axios.interceptors.response.use((response: AxiosResponse) => {
      if (response.headers?.xsrf_token) {
        this.xsrfToken = response.headers.xsrf_token;
      }
      return response;
    });
  }

  createAxiosRefreshTokenInterceptor() {
    axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError<any>) => {
        if (!error.response || !error.response.config) {
          return Promise.reject(error);
        }
        if (!shouldRefreshToken(error.response)) {
          return Promise.reject(
            new APIError(error.response.data, error.response.status)
          );
        }

        if (!this._isRefreshing) {
          this._isRefreshing = true;
          try {
            await this.refreshToken();
            this._isRefreshing = false;
            this._refreshSubscribers.forEach((v) => v.resolve());
            return await axios(this.makeRequestOptions(error.config));
          } catch (err: any) {
            this._isRefreshing = false;
            this._refreshSubscribers.forEach((v) => v.reject());
            return await Promise.reject(
              new APIError(error.response.data, error.response.status)
            );
          } finally {
            this._refreshSubscribers = [];
          }
        }
        return new Promise((resolve, reject) => {
          this._refreshSubscribers.push({
            resolve: () => {
              resolve(axios(this.makeRequestOptions(error.config)));
            },
            reject: () => {
              reject(error);
            },
          });
        });
      }
    );
  }

  get xsrfToken(): string | undefined {
    return this._xsrfToken || getItem(XSRF_TOKEN_KEY);
  }

  set xsrfToken(value: string | undefined) {
    if (value) {
      setItem(XSRF_TOKEN_KEY, value);
      this._xsrfToken = value;
    }
  }

  private buildUrl(url?: string, query?: IQuery): string {
    if (!url) {
      return "";
    }
    let queryString = "";
    if (query) {
      queryString = buildQueryString(query);
    }

    if (url.startsWith("http://") || url.startsWith("https://")) {
      return `${url}${queryString}`;
    }
    if (url[0] === "/") {
      return `${this._baseUrl}${url}${queryString}`;
    }

    return `${this._baseUrl}/${url}${queryString}`;
  }

  private async sendRequest(options: AxiosRequestConfig): Promise<any> {
    const requestOptions = this.makeRequestOptions(options);
    try {
      const response = await axios(requestOptions);
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      throw new APIError(err.response.data, err.response.status);
    }
  }

  private makeRequestOptions(options: AxiosRequestConfig): AxiosRequestConfig {
    let headers: IHeaders = {
      ...this._defaultHeaders,
    };

    if (options.headers) {
      headers = {
        ...headers,
        ...options.headers,
      };
    }

    if (APIClient.shouldSetXsrfTokenHeader(options) && this.xsrfToken) {
      headers[XSRF_HEADER] = this.xsrfToken;
    }

    let opts: AxiosRequestConfig = {
      url: options.url,
      withCredentials: true,
      method: options.method,
      headers,
    };

    if (options.data) {
      if (options.data instanceof FormData) {
        delete headers["Content-Type"];
      }
      opts = {
        ...opts,
        data: options.data,
      };
    }
    return opts;
  }

  private static shouldSetXsrfTokenHeader(
    options: AxiosRequestConfig
  ): boolean {
    if (options.method) {
      const xsrfMethods = ["POST", "PUT", "PATCH", "DELETE"];
      return xsrfMethods.includes(options.method.toUpperCase());
    }
    return false;
  }

  public refreshToken() {
    const requestOptions = this.makeRequestOptions({
      url: this.buildUrl(config.refreshTokenUrl),
      method: "POST",
    });
    return axios(requestOptions);
  }

  public post(
    url: string,
    data: IBody | FormData,
    options: RequestOptions = {}
  ) {
    return this.sendRequest({
      url: this.buildUrl(url, options.query),
      method: "POST",
      data,
      ...options,
    });
  }

  public get(url: string, options: RequestOptions = {}) {
    return this.sendRequest({
      url: this.buildUrl(url, options.query),
      method: "GET",
      ...options,
    });
  }

  public put(
    url: string,
    data: IBody | FormData,
    options: RequestOptions = {}
  ) {
    return this.sendRequest({
      url: this.buildUrl(url, options.query),
      method: "PUT",
      data,
      ...options,
    });
  }

  public patch(
    url: string,
    data: IBody | FormData,
    options: RequestOptions = {}
  ) {
    return this.sendRequest({
      url: this.buildUrl(url, options.query),
      method: "PATCH",
      data,
      ...options,
    });
  }

  public del(url: string, options: RequestOptions = {}) {
    return this.sendRequest({
      url: this.buildUrl(url, options.query),
      method: "DELETE",
      ...options,
    });
  }
}
