import * as React from "react";

import { ITransaction, PaymentScenario } from "../../resources/lease/types";

import styles from "./details.module.css";

interface IProps {
  transaction: ITransaction;
}

export default function PayFrom({ transaction }: IProps) {
  let title = "";
  let subtitle = "";
  let description = "";
  switch (transaction.paymentScenario) {
    case PaymentScenario.REFUND:
      title = "Amount returned";
      break;
    default:
      title = transaction.payerAccount.ownerName;
      subtitle = transaction.payerAccount.name;
      description = `Ending in ${transaction.payerAccount.mask} via ACH`;
      break;
  }

  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>Paid from</div>
      <div className={styles.sectionContent}>
        <div className={styles.title}>{title}</div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        {description && <div className={styles.description}>{description}</div>}
      </div>
    </div>
  );
}
