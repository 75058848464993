/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import cx from "classnames";
import { Formik, FormikProps } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import ActionButton from "../../components/action-button";
import Input from "../../components/input";

import { Head, Header, SubHeader } from "../../components/header";
import { SafeAreaContainer } from "../../components/container";
import Footer from "../../components/footer/footer";

import { authActions } from "../../resources/auth/auth.slice";

import config from "../../config";
import schema from "./schemas/forgot";
import styles from "./auth.module.css";

interface IValues {
  email: string;
}

export default function Forgot() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [recaptchaValue, setRecaptchaValue] = React.useState("");

  async function onSubmit(values: IValues) {
    dispatch(
      authActions.resetPassword({
        email: values.email,
        captcha: recaptchaValue,
      })
    );
  }

  const onChange = (value: string | null) => {
    setRecaptchaValue(value || "");
  };

  const onLoginClick = () => {
    history.push("/login");
  };

  return (
    <>
      <Head>
        <Header>Reset password</Header>
        <SubHeader>
          Provide the email address associated with your account, and if it
          exists, you'll receive an email with instructions to reset your
          password.
        </SubHeader>
      </Head>
      <SafeAreaContainer className={styles.main}>
        <Formik<IValues>
          initialValues={{
            email: "",
          }}
          validateOnBlur
          validateOnChange
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            isValid,
            values,
            errors,
            touched,
          }: FormikProps<IValues>) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <div>
                <div className={styles.inputContainer}>
                  <Input
                    name="email"
                    label="Email"
                    value={values.email}
                    className={cx(styles.input, {
                      [styles.error]: touched.email && errors.email,
                    })}
                    placeholder="Enter your email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email && (
                    <div className={styles.errorMessage}>{errors.email}</div>
                  )}
                </div>
                <div className={styles.recaptcha}>
                  <ReCAPTCHA
                    sitekey={config.recaptchaKey}
                    onChange={onChange}
                  />
                </div>
                <div className={styles.link}>
                  <button type="button" onClick={onLoginClick}>
                    Back to login
                  </button>
                </div>
              </div>
              <ActionButton
                type="submit"
                disabled={!isValid || !recaptchaValue}
              >
                Send
              </ActionButton>
            </form>
          )}
        </Formik>
      </SafeAreaContainer>
      <Footer />
    </>
  );
}
