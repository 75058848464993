import * as React from "react";
import cx from "classnames";

import { centsToDollars } from "../../helpers/common";

import styles from "./balance.module.scss";

interface IProps {
  amount: number;
  positive?: boolean;
  action?: () => void;
}

export default function BalanceNegative({ amount, positive, action }: IProps) {
  return (
    <div
      className={cx(styles.container, !positive ? styles.negative : undefined)}
    >
      {positive && (
        <div className={styles.msg}>
          There was an issue with your payment(s). Your funds will be returned
          in 1-3 business days. Please pay this amount as soon as possible.
        </div>
      )}
      {!positive && (
        <div className={styles.msg}>
          There was an issue with your recent payment(s). Please verify your
          banking information and pay this amount as soon as possible.
        </div>
      )}
      <div className={styles.details}>
        <div className={styles.amount}>
          {positive ? "" : "-"}
          {centsToDollars(amount, { showCents: true })}
        </div>
        {action && (
          <div className={styles.btn} onClick={action}>
            Pay now
          </div>
        )}
      </div>
    </div>
  );
}
