import React from "react";
import MainLayout from "./layouts/main";
import EmptyLayout from "./layouts/empty";

import Login from "./pages/auth/login";
import Forgot from "./pages/auth/forgot";
import Home from "./pages/home";
import History from "./pages/history";
import HistoryDetails from "./pages/history/details";
import ConfirmPayment from "./pages/pay/confirm";
import ChoosePayment from "./pages/pay/choose";
import SubmitPayment from "./pages/pay/submit";
import DwollaVerify from "./pages/verify";
import ConfirmInformation from "./pages/confirm";
import PaymentMethod from "./pages/payment-method";
import PlaidOauthCallback from "./pages/payment-method/plaid-oauth-callback";

import Autopay from "./pages/autopay";
import AutopayAmount from "./pages/autopay/amount";
import AutopaySuccess from "./pages/autopay/success";
import AutopayCanceled from "./pages/autopay/canceled";

import PayNow from "./pages/paynow";
import PayNowAmount from "./pages/paynow/amount";
import PayNowSuccess from "./pages/paynow/success";

import ErrorComponent from "./pages/error";

import config from "./config";

export interface IRoute {
  path: string;
  exact: boolean;
  isPrivate: boolean;
  Layout: Function;
  Component: Function;
  showBackButton?: boolean;
  backUrl?: string;
}

const routes: IRoute[] = [
  {
    path: "/",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: Home,
    showBackButton: false,
  },
  {
    path: "/login",
    exact: true,
    isPrivate: false,
    Layout: EmptyLayout,
    Component: Login,
  },
  {
    path: "/forgot",
    exact: true,
    isPrivate: false,
    Layout: EmptyLayout,
    Component: Forgot,
  },
  {
    path: "/confirm",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: ConfirmInformation,
    backUrl: "/",
  },
  {
    path: "/verify",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: DwollaVerify,
    backUrl: "/",
  },
  {
    path: "/paymentMethod",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: PaymentMethod,
    backUrl: "/",
  },
  {
    path: "/history",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: History,
    backUrl: "/",
  },
  {
    path: "/history/:id",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: HistoryDetails,
    backUrl: "/history",
  },
  {
    path: "/autopay",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: Autopay,
    backUrl: "/?autopay",
  },
  {
    path: "/autopay/paymentMethod",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: PaymentMethod,
    backUrl: "/autopay",
  },
  {
    path: "/autopay/amount",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: AutopayAmount,
  },
  {
    path: "/autopay/success",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: AutopaySuccess,
    showBackButton: false,
  },
  {
    path: "/autopay/cancel/success",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: AutopayCanceled,
    showBackButton: false,
  },
  {
    path: "/paynow",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: PayNow,
    backUrl: "/?autopay",
  },
  {
    path: "/paynow/amount",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: PayNowAmount,
  },
  {
    path: "/paynow/success",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: PayNowSuccess,
    showBackButton: false,
  },
  {
    path: "/payments/:paymentType",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: SubmitPayment,
    backUrl: "/",
  },
  {
    path: "/payments/:paymentType/choose",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: ChoosePayment,
  },
  {
    path: "/payments/:moduleId/success",
    exact: true,
    isPrivate: true,
    Layout: MainLayout,
    Component: ConfirmPayment,
    showBackButton: false,
  },
  {
    path: config.plaidOAuthCallbackUrl,
    exact: true,
    isPrivate: true,
    Layout: EmptyLayout,
    Component: PlaidOauthCallback,
    backUrl: "/paymentMethod",
  },
  {
    path: "/error",
    exact: false,
    isPrivate: false,
    Layout: ({
      children,
    }: {
      children: React.ReactChild | React.ReactChild[];
    }) => children,
    Component: ErrorComponent,
    showBackButton: false,
  },
];

export default routes;
