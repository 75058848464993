import * as React from "react";
import { usePlaidLink, PlaidLinkError, PlaidLinkOnSuccessMetadata, PlaidLinkOnExitMetadata } from "react-plaid-link";
import { getPlaidLinkToken } from "../../helpers/storage";

interface IProps {
  linkToken: string;
  onSuccess: (token: string, metadata: PlaidLinkOnSuccessMetadata) => void;
  onExit: (error: PlaidLinkError | null, metadata?: PlaidLinkOnExitMetadata) => void;
  isOauth?: boolean;
}

function PlaidLink({ linkToken, onSuccess, onExit, isOauth }: IProps) {
  const config = {
    token: isOauth ? getPlaidLinkToken() || "" : linkToken,
    onSuccess,
    receivedRedirectUri: isOauth ? window.location.href : undefined,
    onExit,
  };

  const { open, ready, error } = usePlaidLink(config);

  React.useEffect(() => {
    if (ready) {
      open();
    }
    if (error) {
      onExit(null, undefined);
    }
  }, [ready, open, onExit, error]);

  return null;
}

export default PlaidLink;
