/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment-timezone";

import {
  ILeaseState,
  IAddPaymentMethod,
  IPaymentMethod,
  ISubmitModule,
  IDwollaCustomer,
  ILeaseResponse,
  IAutopay,
  ITransaction,
  ITransactionResponse,
  ILoadingState,
  VerificationStatus,
  IAutopayRequest,
  ILeaseBalance,
  LeasePermission,
  ICreateModule,
} from "./types";
import { parseLeaseResponse, isPaymentMethodSelectable } from "./lease.service";
import { epochSecondsToDate } from "../../helpers/date";
import { IResponse } from "../types";

export const initialState: ILeaseState = {
  paymentMethods: undefined,
  selectedPaymentMethodId: undefined,
  lease: undefined,
  customRentAmount: undefined,
  dwollaCustomer: undefined,
  error: undefined,
  success: undefined,
  plaidLinkToken: undefined,
  transactions: undefined,
  loading: {
    transactions: false,
    paymentMethods: false,
    lease: false,
    homePage: false,
    autopay: false,
    leaseBalance: false,
  },
  paynow: {
    paymentDate: moment().startOf("day").toDate(),
    isMax: true,
  },
  rent: {
    balance: undefined,
    autopay: undefined,
    totalAmount: 2850 * 100,
    amount: 0,
    isMax: true,
    paymentMethodId: undefined,
  },
  leasePermissions: undefined,
};

const leaseSlice = createSlice({
  name: "lease",
  initialState,
  reducers: {
    fetchLease(
      state: ILeaseState,
      action: PayloadAction<{ skipLoading?: boolean }>
    ) {
      return {
        ...state,
        loading: {
          ...state.loading,
          lease: true,
        },
      };
    },
    setLease(
      state: ILeaseState,
      action: PayloadAction<IResponse<ILeaseResponse>>
    ) {
      const newLease = action.payload.payload.message as ILeaseResponse;
      const lease = parseLeaseResponse(newLease, state.leasePermissions || []);
      return {
        ...state,
        lease: {
          ...state.lease,
          ...lease,
        },
        loading: {
          ...state.loading,
          lease: false,
        },
      };
    },

    fetchLeaseBalance(
      state: ILeaseState,
      action: PayloadAction<{ year: number; month: number }>
    ) {
      return {
        ...state,
        loading: {
          ...state.loading,
          leaseBalance: true,
        },
      };
    },

    setLeaseBalance(
      state: ILeaseState,
      action: PayloadAction<ILeaseBalance | null>
    ) {
      return {
        ...state,
        rent: {
          ...state.rent,
          balance: action.payload,
        },
        loading: {
          ...state.loading,
          leaseBalance: false,
        },
      };
    },

    fetchPaymentMethods(state: ILeaseState) {
      return {
        ...state,
        loading: {
          ...state.loading,
          paymentMethods: true,
        },
      };
    },
    setPaymentMethods(
      state: ILeaseState,
      action: PayloadAction<IResponse<IPaymentMethod[]>>
    ) {
      const paymentMethods = action.payload.payload.message || [];
      const selectablePaymentMethods = paymentMethods.filter(
        (x: IPaymentMethod) => isPaymentMethodSelectable(x)
      );
      let selectedPaymentMethodId =
        selectablePaymentMethods.length === 0
          ? undefined
          : selectablePaymentMethods.slice(-1)[0].id;

      if (
        state.selectedPaymentMethodId &&
        selectablePaymentMethods.find(
          (pm) => pm.id === state.selectedPaymentMethodId
        )
      ) {
        selectedPaymentMethodId = state.selectedPaymentMethodId;
      }

      return {
        ...state,
        paymentMethods,
        selectedPaymentMethodId,
        loading: {
          ...state.loading,
          paymentMethods: false,
        },
      };
    },

    confirmInformation(
      state: ILeaseState,
      action: PayloadAction<{ moduleId: string }>
    ) {},

    updateDwollaUserConsentAndCustomerId(
      state: ILeaseState,
      action: PayloadAction<{ customerId?: string; delayInMs?: number }>
    ) {},

    addPaymentMethod(
      state: ILeaseState,
      action: PayloadAction<IAddPaymentMethod>
    ) {},

    removePaymentMethod(
      state: ILeaseState,
      action: PayloadAction<{ id: string }>
    ) {},
    setRemovePaymentMethod(
      state: ILeaseState,
      action: PayloadAction<{ id: string }>
    ) {
      return {
        ...state,
        paymentMethods: state.paymentMethods?.filter(
          (x) => x.id !== action.payload.id
        ),
      };
    },

    selectPaymentMethod(
      state: ILeaseState,
      action: PayloadAction<{ id?: string }>
    ) {
      return {
        ...state,
        selectedPaymentMethodId: action.payload.id,
      };
    },

    fetchDwollaCustomer(
      state: ILeaseState,
      action: PayloadAction<{ delayInMs?: number; redirectTo?: string }>
    ) {},
    setDwollaCustomer(
      state: ILeaseState,
      action: PayloadAction<IResponse<IDwollaCustomer | undefined>>
    ) {
      return {
        ...state,
        dwollaCustomer: action.payload.payload.message,
      };
    },
    setEmptyDwollaCustomer(state: ILeaseState) {
      return {
        ...state,
        dwollaCustomer: null,
      };
    },

    verifyPaymentMethod(
      state: ILeaseState,
      action: PayloadAction<{
        id: string;
        verificationStatus: VerificationStatus;
      }>
    ) {},
    setVerifyPaymentMethtod(
      state: ILeaseState,
      action: PayloadAction<{ id: string }>
    ) {
      return {
        ...state,
      };
    },

    fetchPlaidToken() {},
    setPlaidToken(
      state: ILeaseState,
      action: PayloadAction<{ token?: string }>
    ) {
      return {
        ...state,
        plaidLinkToken: action.payload.token,
      };
    },

    fetchPlaidTokenForPaymentMethod(
      state: ILeaseState,
      action: PayloadAction<{ id: string }>
    ) {},
    setPlaidTokenForPaymentMethod(
      state: ILeaseState,
      action: PayloadAction<{ token?: string }>
    ) {
      return {
        ...state,
        plaidLinkToken: action.payload.token,
      };
    },

    submitModule(state: ILeaseState, action: PayloadAction<ISubmitModule>) {},
    setSubmitModule(state: ILeaseState) {
      return {
        ...state,
      };
    },

    logout(state: ILeaseState) {
      return {
        ...state,
        ...initialState,
      };
    },

    setLoading(
      state: ILeaseState,
      action: PayloadAction<Partial<ILoadingState>>
    ) {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      };
    },

    setAutopayAmount(
      state: ILeaseState,
      action: PayloadAction<{ amount?: number }>
    ) {
      return {
        ...state,
        rent: {
          ...state.rent,
          amount: action.payload.amount,
          isMax: !action.payload.amount,
        },
      };
    },

    fetchAutopay(
      state: ILeaseState,
      action: PayloadAction<{ leaseId: string; skipLoading?: boolean }>
    ) {
      return {
        ...state,
        loading: {
          ...state.loading,
          autopay: true,
        },
      };
    },

    setAutopay(state: ILeaseState, action: PayloadAction<IAutopay | null>) {
      const autopay = action.payload;
      return {
        ...state,
        rent: {
          ...state.rent,
          autopay,
        },
        loading: {
          ...state.loading,
          autopay: false,
        },
      };
    },

    setAutopayPaymentMethod(
      state: ILeaseState,
      action: PayloadAction<{ paymentMethodId: string }>
    ) {
      return {
        ...state,
        rent: {
          ...state.rent,
          paymentMethodId: action.payload.paymentMethodId,
        },
      };
    },

    createAutopay(
      state: ILeaseState,
      action: PayloadAction<{ autopay: IAutopayRequest }>
    ) {
      return {
        ...state,
        loading: {
          ...state.loading,
          autopay: true,
        },
      };
    },

    cancelAutopay(state: ILeaseState, action: PayloadAction<{ id: string }>) {
      return {
        ...state,
        loading: {
          ...state.loading,
          autopay: true,
        },
      };
    },

    setPayNowAmount(
      state: ILeaseState,
      action: PayloadAction<{ amount?: number }>
    ) {
      return {
        ...state,
        paynow: {
          ...state.paynow,
          amount: action.payload.amount,
          isMax: !action.payload.amount,
        },
      };
    },

    setPayNowPaymentMethod(
      state: ILeaseState,
      action: PayloadAction<{ paymentMethodId: string }>
    ) {
      return {
        ...state,
        paynow: {
          ...state.paynow,
          paymentMethodId: action.payload.paymentMethodId,
        },
      };
    },

    submitPayNow(state: ILeaseState) {
      return {
        ...state,
        paynow: {
          paymentDate: moment().startOf("day").toDate(),
          isMax: true,
        },
      };
    },

    fetchTransactions(state: ILeaseState) {
      return {
        ...state,
        loading: {
          ...state.loading,
          transactions: true,
        },
      };
    },
    setTransactions(
      state: ILeaseState,
      action: PayloadAction<IResponse<ITransactionResponse[]>>
    ) {
      const items = action.payload.payload.message;
      if (!items) {
        return {
          ...state,
          transactions: [],
          loading: {
            ...state.loading,
            transactions: false,
          },
        };
      }
      const transactions: ITransaction[] = items.map(
        (t: ITransactionResponse) => ({
          ...t,
          creationTimestamp: epochSecondsToDate(t.creationTimestamp),
          completionTimestamp: t.completionTimestamp
            ? epochSecondsToDate(t.completionTimestamp)
            : undefined,
        })
      );
      return {
        ...state,
        transactions,
        loading: {
          ...state.loading,
          transactions: false,
        },
      };
    },

    cancelTransaction(
      state: ILeaseState,
      action: PayloadAction<{ id: string }>
    ) {},

    setLeasePermissions(
      state: ILeaseState,
      action: PayloadAction<{ permissions: LeasePermission[] }>
    ) {
      return {
        ...state,
        leasePermissions: action.payload.permissions,
      };
    },

    createModule(state: ILeaseState, action: PayloadAction<ICreateModule>) {
      return {
        ...state,
        customRentAmount: undefined,
      };
    },

    setCustomRentAmount(
      state: ILeaseState,
      action: PayloadAction<{ amount: number | undefined }>
    ) {
      return {
        ...state,
        customRentAmount: action.payload.amount,
      };
    },
  },
});

export const leaseActions = leaseSlice.actions;
export const leaseReducer = leaseSlice.reducer;
