/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import cx from "classnames";
import { Formik, FormikProps } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import ActionButton from "../../components/action-button";
import Input from "../../components/input";

import { Head, Header } from "../../components/header";
import { SafeAreaContainer } from "../../components/container";
import Footer from "../../components/footer/footer";

import { authActions } from "../../resources/auth/auth.slice";

import schema from "./schemas/login";
import styles from "./auth.module.css";

interface IValues {
  email: string;
  password: string;
}

export default function LoginPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  async function onSubmit(values: IValues) {
    dispatch(authActions.login(values));
  }

  const onForgotClick = () => {
    history.push("/forgot");
  };

  // if authed, redirect to home page
  React.useEffect(() => {
    dispatch(authActions.checkAuth());
  }, [dispatch]);

  return (
    <>
      <Head>
        <Header>Welcome</Header>
      </Head>
      <SafeAreaContainer className={styles.main}>
        <Formik<IValues>
          initialValues={{
            email: "",
            password: "",
          }}
          validateOnBlur
          validateOnChange
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            isValid,
            values,
            errors,
            touched,
          }: FormikProps<IValues>) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <div>
                <div className={styles.inputContainer}>
                  <Input
                    name="email"
                    label="Email"
                    value={values.email}
                    className={cx(styles.input, {
                      [styles.error]: touched.email && errors.email,
                    })}
                    placeholder="Enter your email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email && (
                    <div className={styles.errorMessage}>{errors.email}</div>
                  )}
                </div>
                <div className={styles.inputContainer}>
                  <Input
                    name="password"
                    label="Password"
                    type="password"
                    value={values.password}
                    className={cx(styles.input, {
                      [styles.error]: touched.password && errors.password,
                    })}
                    placeholder="******"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.password && errors.password && (
                    <div className={styles.errorMessage}>{errors.password}</div>
                  )}
                </div>
                <div className={styles.link}>
                  <button type="button" onClick={onForgotClick}>
                    Forgot password
                  </button>
                </div>
              </div>
              <ActionButton type="submit" disabled={!isValid}>
                Next
              </ActionButton>
            </form>
          )}
        </Formik>
      </SafeAreaContainer>
      <Footer />
    </>
  );
}
