import cx from "classnames";
import * as React from "react";

import { ITransaction, PaymentScenario } from "../../resources/lease/types";

import styles from "./details.module.css";

interface IProps {
  transaction: ITransaction;
}

export default function PayTo({ transaction }: IProps) {
  let title = "";
  let subtitle = "";
  let description = "";
  switch (transaction.paymentScenario) {
    case PaymentScenario.REFUND:
      title = transaction.payeeAccount.ownerName;
      subtitle = transaction.payerAccount.name;
      description = `Ending in ${transaction.payerAccount.mask} via ACH`;
      break;
    case PaymentScenario.BALANCE_COVER:
      title = "Amount due";
      break;
    default:
      title = transaction.payeeAccount.ownerName;
      break;
  }

  return (
    <div className={cx(styles.section, styles.inner)}>
      <div className={styles.sectionTitle}>Paid to</div>
      <div className={styles.sectionContent}>
        <div className={styles.title}>{title}</div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        {description && <div className={styles.description}>{description}</div>}
      </div>
    </div>
  );
}
