import * as React from "react";
import cx from "classnames";

import Button from "../button";

import styles from "./link-button.module.css";

interface IProps extends React.HTMLProps<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
}

export default function LinkButton({
  className,
  children,
  type,
  disabled,
  onClick,
}: IProps) {
  return (
    <Button
      type={type}
      className={cx(styles.link, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
