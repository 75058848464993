/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Footer from "../../components/footer/footer";
import { Head, Header, SubHeader } from "../../components/header";
import {
  IDwollaCustomer,
  ILease,
  IPaymentModule,
  ITask,
  ModulePaymentStatus,
  ModuleStatus,
  ModuleType,
  TaskStatus,
} from "../../resources/lease/types";
import { IStore } from "../../resources/types";
import {
  getNextPaymentDescription,
  getTasks,
  shouldShowPaymentLinks,
} from "../../resources/lease/lease.service";
import Task from "./task";
import Spinner from "../../components/spinner";
import LinkButton from "../../components/link-button";
import { ReactComponent as ArrowIcon } from "../../components/icons/arrow-black.svg";

import styles from "./dashboard.module.scss";

export default function Dashboard() {
  const history = useHistory();

  const lease = useSelector((state: IStore) => state.lease?.lease) as ILease;
  const permissions = useSelector(
    (state: IStore) => state.lease?.leasePermissions
  );
  const dwollaCustomer = useSelector(
    (state: IStore) => state.lease.dwollaCustomer
  ) as IDwollaCustomer;
  const isHomePageLoading = useSelector(
    (state: IStore) => state.lease?.loading.homePage
  );

  const tasks: ITask[] = React.useMemo(() => {
    if (!lease || !permissions) {
      return [];
    }

    return getTasks(lease, permissions, dwollaCustomer);
  }, [lease, permissions, dwollaCustomer]);

  const { due, amount } = React.useMemo(
    () => getNextPaymentDescription(tasks, lease),
    [tasks, lease]
  );

  const showPaymentLinks = React.useMemo(
    () => shouldShowPaymentLinks(lease),
    [lease]
  );

  const onTaskClick = (task: ITask) => {
    if (task.type === ModuleType.CHECKINFO) {
      if (task.module?.status === ModuleStatus.PENDING) {
        history.push("/confirm");
      } else {
        history.push("/verify");
      }
    } else if (
      (task.status === TaskStatus.WARNING &&
        (task.module as IPaymentModule)?.paymentStatus !==
          ModulePaymentStatus.RESUBMIT_PAYMENT) ||
      task.status === TaskStatus.PAYMENT_PENDING
    ) {
      history.push("/history");
    } else {
      history.push(`/payments/${task.type}`);
    }
  };

  function onHistoryClick() {
    history.push("/history");
  }

  function onPaymentMethodClick() {
    history.push("/paymentMethod");
  }

  const content = (
    <>
      {tasks.map((task) => (
        <Task key={task.title} {...task} onClick={() => onTaskClick(task)} />
      ))}

      {showPaymentLinks && (
        <div className={styles.additional}>
          <LinkButton className={styles.linkButton} onClick={onHistoryClick}>
            View payment history
            <ArrowIcon />
          </LinkButton>
          <LinkButton
            className={styles.linkButton}
            onClick={onPaymentMethodClick}
          >
            Manage payment method
            <ArrowIcon />
          </LinkButton>
        </div>
      )}
    </>
  );

  return (
    <>
      <Head>
        <Header>Hi, {lease.primaryResident.user.firstName}.</Header>
        {!!due && !!amount && (
          <SubHeader>
            Your next payment is due on <strong>{due}</strong> with the balance
            of <strong>${amount}</strong>.
          </SubHeader>
        )}
      </Head>

      <div className={styles.home}>
        {isHomePageLoading ? <Spinner /> : content}
      </div>

      <Footer />
    </>
  );
}
