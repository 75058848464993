import {
  IDwollaCustomer,
  DwollaCustomerStatus,
} from "../resources/lease/types";

export default function getText({
  dwollaCustomer,
}: {
  dwollaCustomer?: IDwollaCustomer | null;
}): { title: string; description: string } {
  let title = "Verify identity";
  let description = "Verify your identity to begin making rent payments.";
  switch (dwollaCustomer?.status) {
    case DwollaCustomerStatus.DWOLLA_PENDING_RETRY:
      title = "Review information";
      description =
        "Please verify the correct information has been entered and provide your full SSN.";
      break;
    case DwollaCustomerStatus.DWOLLA_PENDING_DOCUMENT:
    case DwollaCustomerStatus.DWOLLA_PENDING_DOCUMENT_UPLOAD:
      title = "Upload document";
      description =
        "We need further information to proceed. Please upload a valid document to continue.";
      break;
    case DwollaCustomerStatus.DWOLLA_PENDING_DOCUMENT_REVIEW:
      title = "Document in review";
      description =
        "Your document has been successfully uploaded and is being reviewed.";
      break;
    case DwollaCustomerStatus.DWOLLA_DOCUMENT_REVIEW_FAILED:
      title = "Review document";
      description =
        "Please review your document and upload a new one if necessary.";
      break;
    case DwollaCustomerStatus.DWOLLA_SUSPENDED:
    case DwollaCustomerStatus.DWOLLA_DEACTIVATED:
      title = "Failed to confirm identity";
      description =
        "Your identity verification failed. Please reach out to support for assistance.";
      break;
    default:
      break;
  }
  return { title, description };
}
