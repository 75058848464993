import * as React from "react";
import cx from "classnames";

import styles from "./header.module.css";

interface IProps {
  className?: string;
  children: React.ReactChild | React.ReactChild[];
}

export default function SubHeader({ className, children }: IProps) {
  return <div className={cx(styles.subHeader, className)}>{children}</div>;
}
