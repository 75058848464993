/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Head, Header } from "../../components/header";
import PlaidLink from "../../components/payment-methods/plaid";

import { getPlaidLinkToken, setPlaidLinkToken } from "../../helpers/storage";
import { leaseActions } from "../../resources/lease/lease.slice";
import {
  PaymentMethodType,
} from "../../resources/lease/types";
import {
  parseBankAccountType,
  parseVerificationStatus,
} from "../../resources/lease/lease.service";

import styles from "./plaid-oauth-callback.module.css";

export default function PlaidOauthCallback() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [linkToken] = React.useState<string | undefined>(getPlaidLinkToken());

  const onFinish = React.useCallback(() => {
    history.push("/paymentMethod");
  }, [history]);

  React.useEffect(() => {
    if (!linkToken) {
      onFinish();
    }
  }, [linkToken, history, onFinish]);

  async function onPlaidSuccess(
    token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) {
    const account =
      metadata.accounts.length > 0
        ? metadata.accounts[0]
        : (metadata as any).account;
    dispatch(
      leaseActions.addPaymentMethod({
        token,
        plaidAccountId: account.id,
        name: account.name.split(" ")[0],
        mask: account.mask,
        type: PaymentMethodType.ACH,
        bankAccountType: parseBankAccountType(metadata.accounts[0].subtype),
        bankName: metadata.institution?.name,
        verificationStatus: parseVerificationStatus(
          account.verification_status
        ),
      })
    );
    onFinish();
  }
  async function onPlaidExit() {
    dispatch(leaseActions.setPlaidToken({ token: undefined }));
    setPlaidLinkToken(undefined);
    onFinish();
  }

  if (!linkToken) {
    return null;
  }

  return (
    <div className={styles.pay}>
      <Head>
        <Header>Payment method</Header>
      </Head>

      <div className={styles.content}>
        <PlaidLink
          linkToken={linkToken}
          onSuccess={onPlaidSuccess}
          onExit={onPlaidExit}
          isOauth
        />
      </div>
    </div>
  );
}
