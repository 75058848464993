import * as React from "react";

import styles from "./back.module.css";

import { ReactComponent as BackBtn } from "../icons/back-button.svg";
import { ReactComponent as BackBtnWhite } from "../icons/back-button-white.svg";

interface IProps {
  onClick: () => void;
  isBlack?: boolean;
}

export default function BackButton({ onClick, isBlack = true }: IProps) {
  return (
    <div className={styles.back} onClick={onClick}>
      {isBlack ? <BackBtn /> : <BackBtnWhite />}
    </div>
  );
}
BackButton.displayName = "BackButton";
