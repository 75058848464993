import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Head, Header, SubHeader } from "../../components/header";
import Confirm from "../../components/confirm/native";
import LinkButton from "../../components/link-button";
import Settings from "../../components/settings";

import { modalActions } from "../../resources/modal/modal.slice";

import { IPaymentMethod } from "../../resources/lease/types";
import { IStore } from "../../resources/types";
import { leaseActions } from "../../resources/lease/lease.slice";

import styles from "./paynow.module.css";

interface ISetting {
  id: string;
  name: string;
  content: string | React.ReactElement;
  onClick?: () => void;
}

export default function Autopay() {
  const history = useHistory();
  const dispatch = useDispatch();

  const paynow = useSelector((state: IStore) => state.lease?.paynow);
  const paymentMethods = useSelector(
    (state: IStore) => state.lease?.paymentMethods
  );

  let selectedPaymentMethod: IPaymentMethod | undefined =
    paymentMethods && paymentMethods.length > 0 ? paymentMethods[0] : undefined;
  if (paynow.paymentMethodId) {
    selectedPaymentMethod = paymentMethods?.find(
      (x) => x.id === paynow.paymentMethodId
    );
  }

  React.useEffect(() => {
    if (!paymentMethods) {
      dispatch(leaseActions.fetchPaymentMethods());
    }
  }, [dispatch, paymentMethods]);

  React.useEffect(() => {
    if (
      paymentMethods &&
      paymentMethods.length > 0 &&
      !paynow.paymentMethodId &&
      selectedPaymentMethod
    ) {
      dispatch(
        leaseActions.setPayNowPaymentMethod({
          paymentMethodId: selectedPaymentMethod?.id,
        })
      );
    }
    if (paymentMethods && paymentMethods.length > 0 && !selectedPaymentMethod) {
      dispatch(
        leaseActions.setPayNowPaymentMethod({
          paymentMethodId: paymentMethods[0].id,
        })
      );
    }
  }, [dispatch, paymentMethods, paynow, selectedPaymentMethod]);

  const action = () => {
    const modalId = "confirm-submit";
    dispatch(
      modalActions.show({
        id: modalId,
        dependant: true,
        component: (
          <Confirm
            id={modalId}
            title="Pay now"
            text="Are you sure you want to pay now with the provided information?"
            action={() => {
              history.push("/paynow/success");
            }}
            yes="Yes"
            no="Cancel"
          />
        ),
      })
    );
  };

  const onManagePaymentMethodsClick = () => {
    history.push("/paymentMethod");
  };

  const settings: ISetting[] = [
    {
      id: "amount",
      name: "Amount",
      content: "Current balance",
    },
    {
      id: "date",
      name: "Pay date",
      content: "Today",
    },
    {
      id: "pm",
      name: "Payment method",
      content: (
        <LinkButton
          className={styles.setup}
          onClick={onManagePaymentMethodsClick}
        >
          {selectedPaymentMethod
            ? `${selectedPaymentMethod.name} *${selectedPaymentMethod.mask}`
            : "Add"}
        </LinkButton>
      ),
    },
  ];

  return (
    <div className={styles.pay}>
      <Head>
        <Header>Pay now</Header>
        <SubHeader>
          The amount set will be paid from your linked payment method on the pay
          date.
        </SubHeader>
      </Head>

      <div className={styles.content}>
        <Settings settings={settings} />
        <div className={styles.note}>
          Please note that payments received before 5:00 pm EST will be
          processed the same day. Payments received after this time will be
          credited for the day they were submitted, but will not be processed
          until the following business date.
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        <LinkButton onClick={action} className={styles.save}>
          Submit payment
        </LinkButton>
      </div>
    </div>
  );
}
