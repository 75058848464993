/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Head, Header, SubHeader } from "../../components/header";
import AmountForm, { IValues } from "../../components/amount";

import { IRent } from "../../resources/lease/types";
import { IStore } from "../../resources/types";
import { leaseActions } from "../../resources/lease/lease.slice";

import styles from "./amount.module.css";

export default function AutopayAmount() {
  const history = useHistory();
  const dispatch = useDispatch();

  const rent = useSelector((state: IStore) => state.lease?.rent) as IRent;

  async function onSubmit(values: IValues) {
    dispatch(
      leaseActions.setAutopayAmount({
        amount: values.amount ? values.amount * 100 : undefined,
      })
    );
    history.push("/autopay");
  }

  return (
    <>
      <Head>
        <Header>Set limit</Header>
        <SubHeader>
          Your payment will be charged the current amount due up to the limit
          you specify.
        </SubHeader>
      </Head>

      <div className={styles.content}>
        <AmountForm
          amount={rent.amount}
          isMax={rent.isMax}
          totalAmount={rent.totalAmount}
          onSubmit={onSubmit}
        />
      </div>
    </>
  );
}
