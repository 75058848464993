import * as React from "react";
import cx from "classnames";

import Button from "../button";

import styles from "./action-button.module.css";

interface IProps extends React.HTMLProps<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
  containerClassName?: string;
  small?: boolean;
}

export default function ActionButton({
  className,
  children,
  disabled,
  small,
  onClick,
  containerClassName,
  type = "button",
}: IProps) {
  return (
    <div className={cx(styles.actions, containerClassName)}>
      <Button
        type={type}
        disabled={disabled}
        className={cx(styles.btn, small ? styles.small : null, className)}
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  );
}
