import React from "react";
import {
  Switch,
  Route,
  withRouter,
  matchPath,
  RouteComponentProps,
} from "react-router-dom";
import NotFound from "./404";
import Initialize from "../initialize";
import routes, { IRoute } from "../routes";

interface IProps extends RouteComponentProps {}

function Routes({ location }: IProps) {
  const items = routes.map((route: IRoute) => {
    const routeComponent = (props: object) => (
      <route.Layout
        {...props}
        showBackButton={route.showBackButton}
        backUrl={route.backUrl}
      >
        <route.Component {...props} />
      </route.Layout>
    );

    if (route.isPrivate) {
      return (
        matchPath(location.pathname, {
          path: route.path,
          exact: route.exact,
        }) && (
          <Initialize key={route.path}>
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={routeComponent} 
            />
          </Initialize>
        )
      );
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        exact={route.exact}
        component={routeComponent} 
      />
    );
  });

  return (
    <Switch>
      {items}
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default withRouter(Routes);
