import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Head, Header, SubHeader } from "../../components/header";
import Dwolla from "../../components/dwolla";
import { leaseActions } from "../../resources/lease/lease.slice";
import verifyMessages from "../../helpers/verifyMessages";
import { IStore } from "../../resources/types";
import {
  ILease,
  IModule,
  ModuleStatus,
  DwollaCustomerStatus,
} from "../../resources/lease/types";

import styles from "./verify.module.css";

export default function DwollaConfirm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = React.useState("");

  const dwollaCustomer = useSelector(
    (state: IStore) => state.lease.dwollaCustomer
  );
  const lease = useSelector((state: IStore) => state.lease.lease) as ILease;

  const confirmModule = lease.modules.find(
    (x) => x.type === "CHECKINFO"
  ) as IModule;

  React.useEffect(() => {
    if (
      dwollaCustomer?.status === DwollaCustomerStatus.DWOLLA_VERIFIED ||
      dwollaCustomer?.status === DwollaCustomerStatus.DWOLLA_DEACTIVATED ||
      dwollaCustomer?.status === DwollaCustomerStatus.DWOLLA_SUSPENDED
    ) {
      history.push("/");
    }
  }, [dwollaCustomer, history]);

  React.useEffect(() => {
    if (confirmModule.status !== ModuleStatus.COMPLETED) {
      history.push("/confirm");
    }
  }, [confirmModule, history]);

  const onDwollaSuccess = (customerId: string) => {
    setError("");
    dispatch(
      leaseActions.updateDwollaUserConsentAndCustomerId({
        customerId: customerId && !dwollaCustomer ? customerId : undefined,
        delayInMs: 1000,
      })
    );
    history.push("/");
  };

  const onDwollaError = () => {
    // setError(errorMessage);
    // dwolla errors are displayed automatically
  };

  const { title } = verifyMessages({ dwollaCustomer });

  return (
    <>
      <Head>
        <Header>{title}</Header>
        <SubHeader>
          Enter your personal details into the secure form provided by our
          payment services vendor, Dwolla, to verify your identity.
        </SubHeader>
      </Head>
      <div className={styles.pay}>
        <div className={styles.body}>
          {error && <div className={styles.error}>{error}</div>}
          <Dwolla
            resident={lease?.primaryResident}
            dwollaCustomer={dwollaCustomer}
            onSuccess={onDwollaSuccess}
            onError={onDwollaError}
          />
        </div>
      </div>
    </>
  );
}
