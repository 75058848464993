import * as React from "react";

import styles from "./amount-input.module.scss";

interface IProps {
  initialValue?: number;
  onChange: (amount: number) => void;
  placeholder?: number;
}

export default function AmountInput({
  onChange,
  initialValue,
  placeholder = 0,
}: IProps) {
  const [amount, setAmount] = React.useState(initialValue?.toString() || "");

  React.useEffect(() => {
    let num = +amount;

    if (Number.isNaN(num)) {
      const decimal = /[.]/g.exec(amount);
      if (decimal && decimal.index === amount.length - 1) {
        num = +amount.slice(0, -1);
      } else {
        num = 0;
      }
    }

    onChange(num);
  }, [amount, onChange]);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const lastChar = value.slice(-1);
    const isDeleteEvent = [
      "deleteContentBackward",
      "deleteContentForward",
    ].includes((e.nativeEvent as any).inputType);

    // if it already contains decimal dot and latest char is also dot, ignore it
    // if it's neither a number nor a decimal dot, also ignore it
    // limit number of decimals to 2
    if (
      !isDeleteEvent &&
      ((amount.match(/[.]/) &&
        (lastChar.match(/[.]/) || amount.indexOf(".") === amount.length - 3)) ||
        !lastChar.match(/[.\d]/))
    ) {
      e.target.value = amount;
    } else {
      setAmount(value);
    }
  };

  return (
    <label className={styles.amountInput}>
      <span>$</span>
      <span className={styles.inputContainer}>
        <input
          value={amount}
          type="text"
          inputMode="decimal"
          onChange={changeHandler}
          placeholder={placeholder.toString()}
          maxLength={8}
        />
        <span className={styles.placeholder}>{amount || placeholder}</span>
      </span>
    </label>
  );
}
