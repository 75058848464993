export interface IBody {
  [key: string]: any;
}

export interface IQuery {
  [key: string]: any;
}

export interface IHeaders {
  [key: string]: string;
}

export interface IXHR {
  abort: () => any;
}

export type RequestOptions = {
  headers?: IHeaders;
  query?: IQuery;
};

export const XSRF_TOKEN_KEY = "latch_xsrf_token";
export const XSRF_HEADER = "X-XSRF-TOKEN";
export const ERRORS = {
  EXPIRED_TOKEN: "EXPIRED_TOKEN",
  INVALID_TOKEN: "INVALID_TOKEN",
};
export const REQUEST_TYPES = {
  CreateSession: "createSession",
};
