import cx from "classnames";
import moment from "moment-timezone";
import * as React from "react";

import Status from "../../components/status";

import {
  ITransaction,
  TransactionStatus,
  TRANSACTION_STATUSES,
} from "../../resources/lease/types";
import { centsToDollars } from "../../helpers/common";

import styles from "./transaction.module.css";

interface IProps {
  transaction: ITransaction;
  className?: string;
  onClick?: (transaction: ITransaction) => void;
}

export default function Transaction({
  transaction,
  className,
  onClick,
}: IProps) {
  return (
    <div
      key={transaction.id}
      className={cx(styles.payment, className)}
      onClick={() => onClick && onClick(transaction)}
    >
      <div className={styles.row}>
        <div className={styles.date}>
          {moment(transaction.creationTimestamp).format("M/DD/YY")}
        </div>
        <div className={styles.status}>
          <Status
            pending={transaction.status === TransactionStatus.PENDING}
            error={
              transaction.status === TransactionStatus.FAILED ||
              transaction.status === TransactionStatus.CANCELLED
            }
            completed={transaction.status === TransactionStatus.COMPLETED}
            text={TRANSACTION_STATUSES[transaction.status as TransactionStatus]}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.empty}>&nbsp;</div>
        <div className={styles.amount}>
          {centsToDollars(transaction.amount, {
            showCents: true,
          })}
        </div>
      </div>
    </div>
  );
}
