import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import moment from "moment";

import {
  IPaymentModule,
  IPaymentModuleBase,
  IPaymentModuleResponse,
  LeaseModuleResponse,
  LeaseModuleBase,
  LeaseModule,
  IPaymentMethod,
  ILeaseResponse,
  ILease,
  ModuleType,
  ModuleStatus,
  ModulePaymentStatus,
  VerificationStatus,
  BankAccountType,
  ITask,
  LeasePermission,
  TaskStatus,
  IDwollaCustomer,
  TITLES,
  PaymentType,
  DwollaCustomerStatus,
} from "./types";
import { epochSecondsToDate } from "../../helpers/date";
import verifyMessages from "../../helpers/verifyMessages";

export const shouldIncludeInBalance = (module: IPaymentModule): boolean =>
  module.paymentStatus !== ModulePaymentStatus.PAYMENT_COMPLETED;

export const isPaymentModule = (module: LeaseModuleBase): boolean => {
  const paymentModule = module as IPaymentModuleBase;
  return (
    paymentModule.type === ModuleType.DEPOSIT ||
    paymentModule.type === ModuleType.RENT
  );
};

export const canProceedToModule = (module: LeaseModuleResponse): boolean => {
  switch (module.type) {
    case ModuleType.CHECKINFO:
      return module.status === ModuleStatus.PENDING;
    case ModuleType.DEPOSIT:
    case ModuleType.RENT:
      return [
        ModulePaymentStatus.SUBMIT_PAYMENT,
        ModulePaymentStatus.RESUBMIT_PAYMENT,
        ModulePaymentStatus.REFUND_PENDING,
        ModulePaymentStatus.COVER_BALANCE,
      ].includes((module as IPaymentModuleResponse).paymentStatus);
    default:
      return false;
  }
};

export const isPendingPaymentMethod = (pm: IPaymentMethod): boolean =>
  pm.verificationStatus === VerificationStatus.PENDING_MANUAL_VERIFICATION ||
  pm.verificationStatus === VerificationStatus.PENDING_AUTOMATIC_VERIFICATION;

export const isVerificationUnsuccessful = (pm: IPaymentMethod): boolean =>
  pm.verificationStatus === VerificationStatus.VERIFICATION_EXPIRED ||
  pm.verificationStatus === VerificationStatus.VERIFICATION_FAILED;

export const isPaymentMethodSelectable = (pm: IPaymentMethod) =>
  !isPendingPaymentMethod(pm) && !isVerificationUnsuccessful(pm);

export const getNextPayment = (lease: ILease): IPaymentModule | undefined => {
  const payments = sortBy(
    lease.modules
      .filter(isPaymentModule)
      .filter((x: IPaymentModule) =>
        [
          ModulePaymentStatus.PAYMENT_PENDING,
          ModulePaymentStatus.SUBMIT_PAYMENT,
          ModulePaymentStatus.RESUBMIT_PAYMENT,
          ModulePaymentStatus.COVER_BALANCE,
          ModulePaymentStatus.REFUND_PENDING,
        ].includes(x.paymentStatus)
      ),
    (x) => x.dueDate
  );
  if (payments.length === 0) {
    return undefined;
  }
  return payments[0] as IPaymentModule;
};

export const hasFailedPayment = (module: IPaymentModule): boolean =>
  module.paymentStatus === ModulePaymentStatus.RESUBMIT_PAYMENT ||
  module.paymentStatus === ModulePaymentStatus.REFUND_PENDING ||
  module.paymentStatus === ModulePaymentStatus.COVER_BALANCE;

export const needToCoverBalance = (module: IPaymentModule): boolean =>
  module.paymentStatus === ModulePaymentStatus.COVER_BALANCE;

export const isCoveringBalance = (module: IPaymentModule): boolean =>
  module.paymentStatus === ModulePaymentStatus.BALANCE_COVER_PENDING;

export const isNegativeBalance = (module: IPaymentModule): boolean =>
  needToCoverBalance(module) || isCoveringBalance(module);

export const isPositiveBalance = (module: IPaymentModule): boolean =>
  module.paymentStatus === ModulePaymentStatus.REFUND_PENDING;

export const isModuleCompleted = (module: IPaymentModule): boolean =>
  module.paymentStatus === ModulePaymentStatus.PAYMENT_COMPLETED ||
  module.status === ModuleStatus.COMPLETED;

export const parseBankAccountType = (subType: string): BankAccountType => {
  switch (subType) {
    case "checking":
      return BankAccountType.CHECKING;
    case "savings":
      return BankAccountType.SAVINGS;
    default:
      throw Error("Unsupported bank account type");
  }
};

export const parseVerificationStatus = (status: string): VerificationStatus => {
  switch (status) {
    case "pending_manual_verification":
      return VerificationStatus.PENDING_MANUAL_VERIFICATION;
    case "pending_automatic_verification":
      return VerificationStatus.PENDING_AUTOMATIC_VERIFICATION;
    default:
      return VerificationStatus.INSTANTLY_VERIFIED;
  }
};

export const parseLeaseResponse = (
  lease: ILeaseResponse,
  permissions: LeasePermission[]
): ILease => {
  const hasLeasePay = permissions.includes(LeasePermission.LEASE_PAY);
  const firstRentIndex = lease.modules.findIndex(
    (module) => module.type === ModuleType.RENT
  );

  const getPaymentType = (type: ModuleType, index: number) => {
    if (type === ModuleType.DEPOSIT) {
      return PaymentType.DEPOSIT;
    }

    if (index === firstRentIndex && hasLeasePay) {
      return PaymentType.RENT;
    }
    return PaymentType.MONTHLY;
  };

  const modules: LeaseModule[] = lease.modules.map(
    (m: LeaseModuleResponse, index: number) => {
      const module: LeaseModule = {
        ...m,
        canProceed: canProceedToModule(m),
        dueDate: epochSecondsToDate(m.dueDate),
        completedAt: m.completedAt
          ? epochSecondsToDate(m.completedAt)
          : undefined,
      };
      if (isPaymentModule(m)) {
        const paymentModule = m as IPaymentModuleResponse;
        return {
          ...module,
          paymentStatus: paymentModule.paymentStatus,
          amount: paymentModule.amount,
          totalAmount:
            m.fees && m.fees.length > 0
              ? sumBy(m.fees, (x) => x.amount) + paymentModule.amount
              : paymentModule.amount,
          paymentType: getPaymentType(module.type, index),
          lastPaymentDate: paymentModule.lastPaymentDate
            ? epochSecondsToDate(paymentModule.lastPaymentDate)
            : null,
        } as IPaymentModule;
      }
      return module;
    }
  );
  return {
    ...lease,
    modules,
    moveInDate: epochSecondsToDate(lease.moveInDate),
    startDate: epochSecondsToDate(lease.start),
    endDate: epochSecondsToDate(lease.end),
    totalAmount:
      sumBy(
        modules.filter((x: LeaseModule) => isPaymentModule(x)),
        (x: IPaymentModule) => x.totalAmount
      ) || 0,
    balance:
      sumBy(
        modules
          .filter((x: LeaseModule) => isPaymentModule(x))
          .filter((x: IPaymentModule) => shouldIncludeInBalance(x)),
        (x: IPaymentModule) => x.totalAmount
      ) || 0,
  };
};

export const mapDwollaStatusToTaskStatus = (
  dwollaStatus?: DwollaCustomerStatus
): TaskStatus => {
  switch (dwollaStatus) {
    case DwollaCustomerStatus.DWOLLA_PENDING_RETRY:
    case DwollaCustomerStatus.DWOLLA_DOCUMENT_REVIEW_FAILED:
      return TaskStatus.WARNING;
    case DwollaCustomerStatus.DWOLLA_SUSPENDED:
    case DwollaCustomerStatus.DWOLLA_DEACTIVATED:
      return TaskStatus.ERROR;
    case DwollaCustomerStatus.DWOLLA_VERIFIED:
      return TaskStatus.COMPLETED;
    default:
      return TaskStatus.IN_PROGRESS;
  }
};

const getTaskStatus = (
  module: LeaseModule | null | undefined,
  prev?: ITask,
  dwollaCustomer?: IDwollaCustomer
): TaskStatus => {
  // CHECKINFO module is a special case, as we need to check dwolla status as well.
  if (module?.type === ModuleType.CHECKINFO) {
    if (module.status === ModuleStatus.PENDING) {
      return TaskStatus.IN_PROGRESS;
    }
    return mapDwollaStatusToTaskStatus(dwollaCustomer?.status);
  }

  if (
    module &&
    "paymentStatus" in module &&
    module.paymentStatus === ModulePaymentStatus.PAYMENT_COMPLETED &&
    module.paymentType !== PaymentType.MONTHLY
  ) {
    return TaskStatus.COMPLETED;
  }

  if (
    prev?.status !== TaskStatus.COMPLETED &&
    prev?.status !== TaskStatus.PAYMENT_PENDING
  ) {
    return TaskStatus.DISABLED;
  }

  if (module && "paymentStatus" in module) {
    if (hasFailedPayment(module)) {
      return TaskStatus.WARNING;
    }
    if (
      module.paymentStatus === ModulePaymentStatus.PAYMENT_PENDING ||
      module.paymentStatus === ModulePaymentStatus.BALANCE_COVER_PENDING
    ) {
      return TaskStatus.PAYMENT_PENDING;
    }
  }

  return TaskStatus.IN_PROGRESS;
};

const getTaskDescription = (
  moduleType: ModuleType | PaymentType,
  status: TaskStatus,
  amount: number,
  dueDate: string,
  completedOn?: string,
  initiatedOn?: string
): string => {
  if (status === TaskStatus.COMPLETED) {
    return `Completed${completedOn ? ` on ${completedOn}` : ""}.`;
  }
  if (status === TaskStatus.IN_PROGRESS || status === TaskStatus.DISABLED) {
    if (moduleType === ModuleType.CHECKINFO) {
      return "Verify your identity to begin making rent payments.";
    }

    const title = TITLES[moduleType].toLowerCase();
    const amountStr = amount.toLocaleString();

    return `Submit your ${title} of $${amountStr} by ${dueDate}.`;
  }
  if (status === TaskStatus.WARNING) {
    if (moduleType !== ModuleType.CHECKINFO) {
      return "There was an issue with your payment. View details and resolve.";
    }
  }
  if (status === TaskStatus.PAYMENT_PENDING) {
    return `Payment initiated${initiatedOn ? ` on ${initiatedOn}` : ""}.`;
  }

  return "";
};

const getTaskStatusAndDescription = (
  module: LeaseModule,
  prev?: ITask,
  dwollaCustomer?: IDwollaCustomer
): { status: TaskStatus; description: string } => {
  const status = getTaskStatus(module, prev, dwollaCustomer);
  let description = "";

  if (
    module?.type === ModuleType.CHECKINFO &&
    module?.status === ModuleStatus.COMPLETED &&
    dwollaCustomer?.status !== DwollaCustomerStatus.DWOLLA_VERIFIED
  ) {
    description = verifyMessages({ dwollaCustomer }).description;
  } else {
    description = getTaskDescription(
      module.type,
      status,
      ("totalAmount" in module && module.totalAmount / 100) || 0,
      moment(module.dueDate).format("MMMM D, YYYY"),
      module.completedAt
        ? moment(module.completedAt).format("MMMM D, YYYY")
        : "",
      "lastPaymentDate" in module && module.lastPaymentDate
        ? moment(module.lastPaymentDate).format("MMMM D, YYYY")
        : ""
    );
  }

  return { status, description };
};

const getMonthlyRentDescription = (
  amount: number,
  lastModule?: IPaymentModule
) => {
  if (!lastModule || lastModule.paymentStatus === ModulePaymentStatus.SUBMIT_PAYMENT) {
    return `Submit your monthly charges of $${amount.toLocaleString()} by first of every month.`;
  }

  if (hasFailedPayment(lastModule)) {
    return "There was a problem with your last payment. Please review.";
  }

  const amountStr = (lastModule.amount / 100).toLocaleString();
  const date = moment(lastModule.completedAt).format("MMMM D, YYYY");

  return `Your last payment of $${amountStr} was submitted on ${date}.`;
};

export const getTasks = (
  lease: ILease,
  permissions: LeasePermission[],
  dwollaCustomer: IDwollaCustomer
): ITask[] => {
  // Ignore all MONTHLY rent modules
  const modules = lease.modules
    .filter(
      (module) =>
        !isPaymentModule(module) ||
        ("paymentType" in module &&
          module.paymentType !== PaymentType.MONTHLY &&
          module.amount > 0 &&
          (lease.isActive ||
          (module as IPaymentModule).paymentStatus === ModulePaymentStatus.RESUBMIT_PAYMENT ||
          (module as IPaymentModule).paymentStatus === ModulePaymentStatus.COVER_BALANCE)
)
    )
    .sort((a, b) => a.sequence - b.sequence);

  // Map modules to tasks
  const tasks: ITask[] = [];
  modules.forEach((module) => {
    const prev = tasks[tasks.length - 1];

    tasks.push({
      module,
      type: module.type,
      title: TITLES[module.type],
      ...getTaskStatusAndDescription(module, prev, dwollaCustomer),
    });
  });

  // Add monthly charges task based on permissions
  if (permissions.includes(LeasePermission.RENT_PAY)) {
    const lastModule = lease.modules
      .filter(
        (module) =>
          "paymentType" in module && module.paymentType === PaymentType.MONTHLY
      )
      .slice(-1)[0] as IPaymentModule | undefined;

    const status = getTaskStatus(
      lastModule,
      tasks[tasks.length - 1],
      dwollaCustomer
    );

    if (lease.isActive) {
      tasks.push({
        type: PaymentType.MONTHLY,
        title: TITLES[PaymentType.MONTHLY],
        description: getMonthlyRentDescription(
          (lease.baseRentAmount || 0) / 100,
          lastModule
        ),
        status,
        module: lastModule,
      });
    }
  }

  return tasks;
};

export const getNextPaymentDescription = (
  tasks: ITask[],
  lease: ILease
): { due: string; amount: string } => {
  const task = tasks.find(
    (t) =>
      t.type !== ModuleType.CHECKINFO &&
      t.status !== TaskStatus.COMPLETED &&
      t.status !== TaskStatus.PAYMENT_PENDING
  );

  const module = task?.module as IPaymentModule | undefined;
  let due = "";
  let amount = "";

  if (task?.type === PaymentType.MONTHLY) {
    due = moment().endOf("month").format("MMMM D, YYYY");
    amount = ((lease.baseRentAmount || 0) / 100).toLocaleString();
  } else if (task) {
    due = moment(module?.dueDate).format("MMMM D, YYYY");
    amount = ((module?.totalAmount || 0) / 100).toLocaleString();
  }

  return { due, amount };
};

// If a payment has been initiated in any of the payment modules, then show payment links
export const shouldShowPaymentLinks = (lease: ILease): boolean =>
  !!lease.modules.find(
    (module: IPaymentModule) =>
      isPaymentModule(module) &&
      (module.status === ModuleStatus.COMPLETED ||
        module.paymentStatus !== ModulePaymentStatus.SUBMIT_PAYMENT)
  );
