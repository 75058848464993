import { all, fork } from "redux-saga/effects";

import { leaseWatcher } from "./lease/lease.saga";
import { authWatcher } from "./auth/auth.saga";
import { commonWatcher } from "./common/common.saga";
import { modalWatcher } from "./modal/modal.saga";

const rootSaga = function* rootSaga() {
  yield all([
    fork(leaseWatcher),
    fork(authWatcher),
    fork(commonWatcher),
    fork(modalWatcher),
  ]);
};
export default rootSaga;
