import { IModule, ModuleType } from "../resources/lease/types";

function centsToDollars(
  cents: number,
  options?: { skipDollarSign?: boolean; showCents?: boolean }
): string {
  return `${options?.skipDollarSign ? "" : "$"}${(cents / 100).toLocaleString(
    "en-US",
    {
      maximumFractionDigits: 2,
      minimumFractionDigits: options?.showCents ? 2 : undefined,
    }
  )}`;
}

const moneyFormatterInteger = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const moneyFormatterDecimal = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

function formatMoney(amount: number, decimal = true): string {
  const formatter = decimal ? moneyFormatterDecimal : moneyFormatterInteger;
  return formatter.format(amount);
}

const getTaskTitle = ({ task }: { task: IModule }): string => {
  switch (task.type) {
    case ModuleType.DEPOSIT:
      return "Security deposit";
    case ModuleType.RENT:
      return "First month's rent";
    default:
      return "Rent payment";
  }
};

export { centsToDollars, formatMoney, getTaskTitle };
