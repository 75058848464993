import * as React from "react";
import { useLocation } from "react-router-dom";

import styles from "./error.module.css";

function GeneralError() {
  const location = useLocation();

  let msg: React.ReactNode = null;
  if (location.state && location.state.message) {
    msg = <div className={styles.description}>{location.state.message}</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Hang tight</h1>
        <div className={styles.text}>There’s an issue we need to work out.</div>
        <div className={styles.text}>{msg}</div>
      </div>
    </div>
  );
}

GeneralError.displayName = "GeneralError";
export default GeneralError;
