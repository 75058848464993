import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import LatchLogo from "../../assets/latch-logo.svg";
import { ReactComponent as ArrowIcon } from "../icons/arrow-black.svg";
import config from "../../config";
import { authActions } from "../../resources/auth/auth.slice";
import { IStore } from "../../resources/types";

import styles from "./footer.module.css";

export default function Footer() {
  const dispatch = useDispatch();
  const lease = useSelector((state: IStore) => state.lease?.lease);

  const logout = () => {
    dispatch(authActions.logout());
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.linkContainer}>
        <a href={config.privacyPolicy} className={styles.link}>
          Privacy policy
        </a>
        <a href={config.termsOfService} className={styles.link}>
          Terms of Service
        </a>
        <a href={config.supportPage} className={styles.link}>
          Support
        </a>

        <hr />

        {lease && (
          <div className={cx(styles.link, styles.signOut)} onClick={logout}>
            Sign Out
            <ArrowIcon />
          </div>
        )}
      </div>

      <img src={LatchLogo} alt="Latch" className={styles.logo} />
    </footer>
  );
}
