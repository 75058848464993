import { createStore, applyMiddleware, AnyAction, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { History } from "history";
import { IStore } from "./types";
import createReducer from "./reducer";
import rootSaga from "./sagas";

export default function configureStore(initialState: IStore, history: History) {
  const sagaMiddleware = createSagaMiddleware();
  const store: Store = createStore<IStore | undefined, AnyAction, {}, {}>(
    createReducer(history),
    initialState,
    composeWithDevTools(
      applyMiddleware(routerMiddleware(history), sagaMiddleware)
    )
  );

  if (module.hot) {
    module.hot.accept("./reducer", () => {
      store.replaceReducer(createReducer(history));
    });
  }

  sagaMiddleware.run(rootSaga);

  return store;
}
