/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import cx from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { PlaidLinkError, PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import PlaidLink from "./plaid";
import LinkButton from "../link-button";
import ConfirmNative from "../confirm/native";
import Confirm from "../confirm";
import Status from "../status";
import { leaseActions } from "../../resources/lease/lease.slice";
import { modalActions } from "../../resources/modal/modal.slice";
import { commonActions } from "../../resources/common/common.slice";
import {
  IPaymentMethod,
  PaymentMethodType,
  VerificationStatus,
  PAYMENT_METHOD_STATUSES,
  PlaidErrorCode,
} from "../../resources/lease/types";
import {
  isPendingPaymentMethod,
  isVerificationUnsuccessful,
  isPaymentMethodSelectable,
  parseBankAccountType,
  parseVerificationStatus,
} from "../../resources/lease/lease.service";
import { IStore } from "../../resources/types";

import { ReactComponent as RemoveIcon } from "../icons/cross.svg";

import styles from "./payment-methods.module.css";

interface IProps {
  onChange?: (id: string) => void;
}

const MAX_BANK_ACCOUNTS = 2;

export default function PaymentMethods({ onChange }: IProps) {
  const dispatch = useDispatch();

  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = React.useState<
    string | undefined
  >(undefined);
  const paymentMethods = useSelector(
    (state: IStore) => state.lease?.paymentMethods || []
  );
  const plaidLinkToken = useSelector(
    (state: IStore) => state.lease?.plaidLinkToken
  );
  React.useLayoutEffect(() => {
    dispatch(leaseActions.fetchPaymentMethods());
  }, [dispatch]);

  const deletePaymentMethod = (pm: IPaymentMethod) => {
    const modalId = "confirm-delete-pm";
    dispatch(
      modalActions.show({
        id: modalId,
        dependant: true,
        component: (
          <ConfirmNative
            id={modalId}
            title="Delete payment method"
            text={`Are you sure you want to delete ${pm?.name} ending in ${pm?.mask}?`}
            action={() => {
              dispatch(leaseActions.removePaymentMethod({ id: pm.id }));
            }}
            isRed
          />
        ),
      })
    );
  };

  async function handleAddBankAccount() {
    dispatch(leaseActions.fetchPlaidToken());
  }

  const openAddBankAccount = () => {
    const modalId = "confirm-dwolla";
    dispatch(
      modalActions.show({
        id: modalId,
        dependant: true,
        component: (
          <Confirm
            id={modalId}
            title="Please agree to the terms before linking your payment account."
            description="I agree that future payments to Latch will be processed by the Dwolla payment system from the selected account above. In order to cancel this authorization, I will need to change the payment settings in my account."
            yes="Agree and Continue"
            action={() => handleAddBankAccount()}
            titleClassName={styles.confirmTitle}
            descriptionClassName={styles.confirmDescription}
          />
        ),
      })
    );
  };

  const onAddPaymentMethod = () => {
    if (paymentMethods?.length >= MAX_BANK_ACCOUNTS) {
      dispatch(
        commonActions.setError({
          title: "BANK_ACCOUNT_LIMIT_REACHED",
          text: "You may only add up to two bank accounts. Remove an existing account in order to add another, or contact Support for assistance.",
        })
      );
      return;
    }

    openAddBankAccount();
  };

  async function openVerifyBankAccount(pmId: string) {
    setSelectedPaymentMethodId(pmId);
    dispatch(
      leaseActions.fetchPlaidTokenForPaymentMethod({
        id: pmId,
      })
    );
  }

  async function onVerifyBankAccount(
    newVerificationStatus: VerificationStatus
  ) {
    if (!selectedPaymentMethodId) {
      return;
    }
    dispatch(
      leaseActions.verifyPaymentMethod({
        id: selectedPaymentMethodId,
        verificationStatus: newVerificationStatus,
      })
    );
    setSelectedPaymentMethodId(undefined);
    dispatch(leaseActions.fetchPaymentMethods());
  }

  async function onAddBankAccount(
    token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) {
    const account =
      metadata.accounts.length > 0
        ? metadata.accounts[0]
        : (metadata as any).account;
    dispatch(
      leaseActions.addPaymentMethod({
        token,
        plaidAccountId: account.id,
        name: account.name.split(" ")[0],
        mask: account.mask,
        type: PaymentMethodType.ACH,
        bankAccountType: parseBankAccountType(metadata.accounts[0].subtype),
        bankName: metadata.institution?.name,
        verificationStatus: parseVerificationStatus(
          account.verification_status
        ),
      })
    );
  }

  async function onPlaidSuccess(
    token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) {
    dispatch(leaseActions.setPlaidToken({ token: undefined }));
    const account =
      metadata.accounts.length > 0
        ? metadata.accounts[0]
        : (metadata as any).account;
    if (account.verification_status === "manually_verified") {
      return onVerifyBankAccount(VerificationStatus.MANUALLY_VERIFIED);
    }

    return onAddBankAccount(token, metadata);
  }

  async function onPlaidExit(error: PlaidLinkError | null) {
    if (
      error != null &&
      error.error_code === PlaidErrorCode.TOO_MANY_VERIFICATION_ATTEMPTS
    ) {
      onVerifyBankAccount(VerificationStatus.VERIFICATION_FAILED);
    }
    dispatch(leaseActions.setPlaidToken({ token: undefined }));
    dispatch(leaseActions.fetchPaymentMethods());
  }

  const orderedPaymentsMethods = React.useMemo(
    () =>
      paymentMethods.reduce(
        (acc: IPaymentMethod[], element: IPaymentMethod) => {
          if (isPendingPaymentMethod(element)) {
            return [element, ...acc];
          }
          return [...acc, element];
        },
        []
      ),
    [paymentMethods]
  );

  return (
    <div className={styles.container}>
      {orderedPaymentsMethods.map((pm: IPaymentMethod) => (
        <div
          key={pm.id}
          className={cx(
            styles.item,
            onChange && !isPaymentMethodSelectable(pm)
              ? styles.disabled
              : undefined
          )}
        >
          <div
            className={onChange ? styles.clickable : undefined}
            onClick={() =>
              onChange && isPaymentMethodSelectable(pm)
                ? onChange(pm.id)
                : undefined
            }
          >
            <div className={styles.nameRow}>
              <div className={styles.name}>{pm.name}</div>
              {PAYMENT_METHOD_STATUSES[pm.verificationStatus] && (
                <Status
                  className={styles.status}
                  pending={!isVerificationUnsuccessful(pm)}
                  error={isVerificationUnsuccessful(pm)}
                  text={PAYMENT_METHOD_STATUSES[pm.verificationStatus]}
                />
              )}
            </div>
            {pm.mask && (
              <div className={styles.description}>{`Ending in ${pm.mask}`}</div>
            )}
          </div>
          {pm.verificationStatus ===
            VerificationStatus.PENDING_MANUAL_VERIFICATION && (
            <LinkButton
              className={styles.verifyBtn}
              onClick={() => openVerifyBankAccount(pm.id)}
            >
              Verify
            </LinkButton>
          )}
          <div className={styles.remove}>
            <RemoveIcon onClick={() => deletePaymentMethod(pm)} />
          </div>
        </div>
      ))}
      <LinkButton className={styles.addBtn} onClick={onAddPaymentMethod}>
        Add payment method
      </LinkButton>
      {plaidLinkToken && (
        <PlaidLink
          linkToken={plaidLinkToken}
          onSuccess={onPlaidSuccess}
          onExit={onPlaidExit}
        />
      )}
    </div>
  );
}
